import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';
import { createClient } from '@supabase/supabase-js';
import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class VonnisCreate extends React.Component {

    state = {
        user: {},
        cocNumber: '',
        companyName: '',
        email: '',
        phone: '',
        vonnisDate: '',
        vonnisPrice: '',
        vonnisNumber: '',
        vonnisPdf: {}
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user});
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    vonnisCreate(e) {
		e.preventDefault();

        const duplicateWarning = document.querySelector('.duplicate-error');
        duplicateWarning.classList.add('hidden');

        const phoneWarning = document.querySelector('.phone-error');
        phoneWarning.classList.add('hidden');

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        const supabaseUrl = 'https://mufgzqkbodoqkljfbzen.supabase.co';
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
        const supabase = createClient(supabaseUrl, supabaseKey);

        const vonnisUrl = this.state.user.id + '/' + uuidv4();
        supabase
        .storage
        .from('vonnisbank-files')
        .upload(vonnisUrl, this.state.vonnisPdf);

        axios.post(`${baseUrl}/api/vonnis/create`, {
            createdBy: this.state.user.id,
			cocNumber: this.state.cocNumber,
			companyName: this.state.companyName,
			email: this.state.email,
			phone: this.state.phone,
			vonnisDate: this.state.vonnisDate,
			vonnisPrice: this.state.vonnisPrice,
			vonnisNumber: this.state.vonnisNumber,
			vonnisPdf: process.env.REACT_APP_SUPABASE_STORAGE_URL + vonnisUrl,
        },
        { 
            withCredentials: true 
        })
        .then((response) => {
            if (response.data.vonnisMine === 'unsubscribed') {
                this.props.navigate('/aanmelden');
            }

            this.props.navigate('/vonnis/aangemaakt');
        })
        .catch((error) => {
            if (error.response.data.vonnisinfo.code === 11000) {
                duplicateWarning.classList.remove('hidden');
            } else if (error.response.data.vonnisinfo.errors.phone) {
                phoneWarning.classList.remove('hidden');
            } else if (error.response.data.vonnisinfo) {
                console.log(error);
            }
        });
    }

    kvkLookup(e) {

        if (e.type === 'keydown' && e.code !== 'Enter') {
            return;
        }

        if (this.state.cocNumber.length !== 8) {
            return;
        }

        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/kvklookup/${this.state.cocNumber}`)
        .then((response) => {
            var kvkInfoField = document.querySelector('#kvkLookupResult');

            kvkInfoField.innerHTML = "Bezig met zoeken..."

            setTimeout(() => {
                var companyInfo = JSON.parse(response.data.kvk);

                if (companyInfo.resultaten) {
                    kvkInfoField.innerHTML = `
                        <p><b>${companyInfo.resultaten[0].handelsnaam}</b></p>
                        <p>${companyInfo.resultaten[0].plaats}</p>
                        <p>${companyInfo.resultaten[0].straatnaam}</p>
                    `

                    this.setState({companyName: companyInfo.resultaten[0].handelsnaam}, () => {
                        var fieldCompany = document.querySelector('.form-control.companyName');
                        fieldCompany.setAttribute('disabled', true);
                    });

                } else {
                    kvkInfoField.innerHTML = `
                        <p><b>Er zijn geen gegevens gevonden</b></p>
                        <p>Indien u er zeker van bent dat u de juiste gegevens heeft kunt u deze handmatig invullen</p>
                    `
                }
            }, 1000);

        })
        .catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="main-container faq-page">
                <div className="page-intro">
                    <div className="container mx-auto">
                        <h1>Nieuw vonnis registreren</h1>
                        <div className="intro">
                            <p>
                                U kunt hier het vonnis van uw schuldenaar registeren. De schuldenaar krijgt altijd
                                een notificatie per post dat zijn vonnis staat geregistreerd bij vonnisbank.nl.
                                Ook de notificatie per e-mail en/of sms verhoogt uw kans op snellere betaling.
                                Vul daarom het e-mailadres en mobiel telefoonnummer van uw schuldenaar in.
                                Uw gegevens worden niet kenbaar gemaakt in deze notificatie.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-pattern front">
                    <div className="main-container container mx-auto">
                        <div className="form-wrapper">

                            <div className="alert alert-warning duplicate-error hidden" role="alert">Vonnis zaaknummer is al bekend bij Vonnisbank.</div>
                            <div className="alert alert-warning phone-error hidden" role="alert">Vul een telefoonnummer in.</div>

                            <form onSubmit={this.vonnisCreate.bind(this)}>
                                <div className="form-group kvk">
                                    <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} onKeyDown={this.kvkLookup.bind(this)} type="text" placeholder="KvK-nummer" className="form-control" id="kvk" required />
                                    <div className="input-group-btn">
                                        <button className="btn btn-small btn-default" type="button" onClick={this.kvkLookup.bind(this)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="alert alert-info" id="kvkLookupResult">Vul een KvK-nummer in om te zoeken</div>
                                </div>
                                <div className="form-group">
                                    <input value={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} type="text" placeholder="Handelend onder naam" className="form-control companyName" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="text" placeholder="E-mailadres schuldenaar" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <PhoneInput
                                        country={'nl'}
                                        regions={'europe'}
                                        value={this.state.phone}
                                        onChange={phone => this.setState({ phone })}
                                        inputProps={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisDate} onChange={(e) => this.setState({vonnisDate: e.target.value})} type="date" placeholder="Datum vonnis" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisPrice} onChange={(e) => this.setState({vonnisPrice: e.target.value})} type="text" placeholder="Gevorderd bedrag volgens vonnis" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisNumber} onChange={(e) => this.setState({vonnisNumber: e.target.value})} type="text" placeholder="Vonnis zaak nummer" className="form-control" required />
                                </div>
                                {
                                    this.state.user.trustedAccount ? null : <div className="form-group">
                                        <input type="file" accept="application/pdf" className="form-control" onChange={(e) => this.setState({vonnisPdf: e.target.files[0]})} required />
                                    </div>
                                }
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Vonnis plaatsen"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
    
}

function WithNavigate() {
    const navigate = useNavigate();
    return <VonnisCreate navigate={navigate} />
}

export default WithNavigate;