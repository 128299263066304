import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/common';

import Logovisual from "../../images/logo-visual-blue.svg";

import '../../styles/App.scss';
import '../../styles/backend/errorPages.scss';

class RegisterDone extends React.Component {
    
    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container register-done">
                        <div className="page-intro">
                            <img className="logo-visual" src={Logovisual} alt="logo visual" />
                            <h1>Bedankt voor uw aanmelding</h1>
                            <div className="intro">
                                <p>Uw account is nu klaar voor gebruik. Met de door u ingevoerde gegevens kunt u nu inloggen.</p>
                                <p>U krijgt een bevestiging van uw account per email. Mocht deze niet aankomen controleer dan uw spam box/ongewenste email box. U kunt dan ons email adres als gewenst aangeven.</p>
                            </div>
                            <a className="link-arrow" href="/login">Klik hier om in te loggen</a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <RegisterDone navigate={navigate} />
}

export default WithNavigate;