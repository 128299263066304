import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import '../../../styles/App.scss';
import '../../../styles/backend/blondedcms.scss';

class BlondedcmsTools extends React.Component {

    state = {
        //GET ACCOUNT INFO
        user: {},

        //TOOLS
        businessMail: '',
        removeMail: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user || user.role !== 'vonnisbank_admin') {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({ user: user });
                }
            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    makeBusinessAccount(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/admin/tools/makebusiness/${this.state.businessMail}`, {
            withCredentials: true,
        })
        .then(response => { 
            window.location.reload(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    removeAccount(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/admin/tools/account/inactive/${this.state.removeMail}`, {
            withCredentials: true,
        })
        .then(response => { 
            window.location.reload(false);
        })
        .catch(error => {
            console.log(error);
        });
    }
    
    render() {
        return (
            <div className="Main">
                 <div className="blondedcms">
                    <div className="flex flex-col items-center w-16 overflow-hidden blonded-sidebar">
                        <div className="flex items-center justify-center">
                            <svg className="sidebar-icon w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                            </svg>
                        </div>
                        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
                            <a className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-900 hover:text-white" href="/blondedcms/admin">
                                <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </a>      
                        </div>
                        
                        <a className="flex items-center justify-center w-16 h-16 mt-auto hover:bg-gray-900 hover:text-white" href="/account">
                            <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </div>
                    <div className="blonded-content">
                        <div className="cms-wrapper">
                            <div className="cms-header">
                                <h1 className="cms-title">Blonded CMS</h1>
                                <p className="cms-subtitle">Welkom {this.state.user.name}</p>
                            </div>
                            <div className="cms-body">
                                <h2 className="body-title">Admin tools</h2>

                                <form className="admin-tool-form" onSubmit={this.makeBusinessAccount.bind(this)}>
                                    <div className="form-group">
                                        <label><strong>Maak business account</strong></label>
                                        <input value={this.state.businessMail} onChange={(e) => this.setState({businessMail: e.target.value})} type="text" placeholder="Account e-mail" className="form-control bg-white companyName" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" className="button button-primary button-block button-big button-register bg-gray-900" value="Maak business account" />
                                    </div>
                                </form>

                                <form className="admin-tool-form" onSubmit={this.removeAccount.bind(this)}>
                                    <div className="form-group">
                                        <label><strong>Deactiveer een account</strong></label>
                                        <input value={this.state.removeMail} onChange={(e) => this.setState({removeMail: e.target.value})} type="text" placeholder="Account e-mail" className="form-control bg-white companyName" required />
                                    </div>
                                    <div className="form-group">
                                        <input type="submit" className="button button-primary button-block button-big button-register bg-gray-900" value="Deactiveer account" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <BlondedcmsTools navigate={navigate} />
}

export default WithNavigate;