import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

class LinkExpired extends React.Component {
    
    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container error">
                        <h1>401</h1>
                        <h3>Deze link is ongeldig</h3>
                        <div className="error-desc">
                            De link die u heeft gebruikt is ongeldig, probeer het opnieuw door een nieuwe link aan te vragen.
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <LinkExpired navigate={navigate} />
}

export default WithNavigate;