import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class Activate extends React.Component {

    state = {
        user: {},
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    });
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        this.checkAccount();
                    });
                }
            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            });
            this.props.navigate('/login');
        });
    }

    checkAccount() {
        if (this.state.user.subscriber) {
            this.props.navigate('/account');
        }
    }

    activateAccount(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/user/activate/${this.state.user.id}`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                this.props.navigate('/account/aangemeld');
            }
        })
        .catch(error => {
            alert(error);
        });
    }


    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Account activeren</h1>
                            <div className="intro">
                                <p>Om volledig gebruik te maken van Vonnisbank kunt u hieronder uw account activeren.</p>            
                            </div>
                        </div>

                        <div className="form-wrapper">
                        <form onSubmit={this.activateAccount.bind(this)}>
                            <div className="form-group">
                                <div className="checkbox">
                                    <label>
                                        <input type="checkbox" value="1" required /> Ik ga akkoord met de <a href="/kosten" target="_blank">kosten</a> van vonnisbank.nl
                                    </label>
                                </div>
                            </div>
                            <div className="form-group">
                                <input type="submit" className="button button-primary button-block button-big" value="Account activeren"/>
                            </div>
                        </form>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Activate navigate={navigate} />
}

export default WithNavigate;