import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

function App() {
    return (
        <div className="Main">
             <Header />
             <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Algemene voorwaarden</h1>
                        </div>      
                        <p>
                        Algemene Voorwaarden Vonnisbank.nl B.V.</p>
                        <p>
                        Vestigingsadres:</p>
                        <p>
                        Mingersbergstraat 29, 5045EM Tilburg</p>
                        <p>
                        Postadres:</p>
                        <p>
                        Mingersbergstraat 29, 5045EM Tilburg</p>
                        <p>
                        KvK-nummer: 66062438</p>
                        <p>
                        BTW-nummer: NL856379876B01</p>
                        <p>
                        ARTIKEL 1 ALGEMEEN</p>
                        <p>
                        Deze voorwaarden zijn van toepassing op iedere aanbieding, offerte en overeenkomst tussen Vonnisbank.nl B.V. en een contractspartij van Vonnisbank, op welke overeenkomst Vonnisbank deze voorwaarden van toepassing heeft verklaard, voor zover van deze voorwaarden niet door partijen uitdrukkelijk en schriftelijk is afgeweken.</p>
                        <p>
                        De onderhavige voorwaarden zijn eveneens van toepassing op overeenkomsten met Vonnisbank, voor de uitvoering waarvan door Vonnisbank derden worden betrokken.</p>
                        <p>
                        Deze algemene voorwaarden zijn eveneens geschreven voor de medewerkers van Vonnisbank en haar directie.</p>
                        <p>
                        De toepasselijkheid van eventuele inkoop- of andere voorwaarden van Contractspartij wordt uitdrukkelijk van de hand gewezen.</p>
                        <p>
                        Indien één of meerdere bepalingen in deze algemene voorwaarden op enig moment geheel of gedeeltelijk nietig zijn of vernietigd mochten worden, dan blijft het overigens in deze algemene voorwaarden bepaalde volledig van toepassing. Vonnisbank en de Contractspartij zullen alsdan in overleg treden teneinde nieuwe bepalingen ter vervanging van de nietige of vernietigde bepalingen overeen te komen, waarbij zoveel als mogelijk het doel en de strekking van de oorspronkelijke bepalingen in acht wordt genomen.</p>
                        <p>
                        Indien onduidelijkheid bestaat omtrent de uitleg van één of meerdere bepalingen van deze algemene voorwaarden, dan dient de uitleg plaats te vinden `naar de letter' van deze bepalingen.</p>
                        <p>
                        Indien zich tussen partijen een situatie voordoet die niet in deze algemene voorwaarden geregeld is, dan dient deze situatie te worden beoordeeld naar de geest van deze algemene voorwaarden.</p>
                        <p>
                        Indien Vonnisbank niet steeds strikte naleving van deze voorwaarden verlangt, betekent dit niet dat de bepalingen daarvan niet van toepassing zijn, of dat Vonnisbank in enigerlei mate het recht zou verliezen om in andere gevallen de stipte naleving van de bepalingen van deze voorwaarden te verlangen.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 2 OFFERTES EN AANBIEDINGEN</p>
                        <p>
                        Alle offertes en aanbiedingen van Vonnisbank zijn vrijblijvend, tenzij in de offerte een termijn voor aanvaarding is gesteld. Indien geen aanvaardingstermijn is gesteld, kan aan de offerte of aanbieding op generlei wijze enig recht worden ontleend indien het product waarop de offerte of de aanbieding betrekking heeft in de tussentijd niet meer beschikbaar is.</p>
                        <p>
                        Vonnisbank kan niet aan haar offertes of aanbiedingen worden gehouden indien de Contractspartij redelijkerwijs kan begrijpen dat de offertes of aanbiedingen, dan wel een onderdeel daarvan, een kennelijke vergissing of verschrijving bevat.</p>
                        <p>
                        De in een offerte of aanbieding vermelde prijzen zijn exclusief BTW en andere heffingen van overheidswege, eventuele in het kader van de overeenkomst te maken kosten, daaronder begrepen reis- en verblijf-, verzend- en administratiekosten, tenzij anders aangegeven.</p>
                        <p>
                        Indien de aanvaarding - al dan niet op ondergeschikte punten - afwijkt van het in de offerte of de aanbieding opgenomen aanbod dan is Vonnisbank daaraan niet gebonden. De overeenkomst komt dan niet overeenkomstig deze afwijkende aanvaarding tot stand, tenzij Vonnisbank anders aangeeft.</p>
                        <p>
                        Een samengestelde prijsopgave verplicht Vonnisbank niet tot het verrichten van een gedeelte van de overeenkomst tegen een overeenkomstig deel van de opgegeven prijs. Aanbiedingen of offertes gelden niet automatisch voor toekomstige overeenkomsten.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 3 CONTRACTSDUUR, UITVOERINGSTERMIJNEN, RISICO-OVERGANG, UITVOERING EN WIJZIGING OVEREENKOMST, PRIJSVERHOGING</p>
                        <p>
                        De overeenkomst tussen Vonnisbank en de Contractspartij wordt aangegaan voor onbepaalde tijd, tenzij uit de aard van de overeenkomst anders voortvloeit of indien partijen uitdrukkelijk en schriftelijk anders overeenkomen.</p>
                        <p>
                        Is voor de uitvoering van bepaalde werkzaamheden of voor de levering van bepaalde zaken of diensten door Vonnisbank een termijn overeengekomen of opgegeven, dan is dit nimmer een fatale termijn. Bij overschrijding van een termijn dient de Contractspartij Vonnisbank derhalve schriftelijk in gebreke te stellen. Vonnisbank dient daarbij een redelijke termijn te worden geboden om alsnog uitvoering te geven aan de overeenkomst.</p>
                        <p>
                        Vonnisbank zal de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uit te voeren. Een en ander op grond van de op dat moment bekende stand der wetenschap. De verplichting daartoe is een inspanningsverplichting.</p>
                        <p>
                        Vonnisbank heeft het recht bepaalde werkzaamheden te laten verrichten door derden. De toepasselijkheid van artikel 7:404, 7:407 lid 2 en 7:409 BW wordt uitdrukkelijk uitgesloten.</p>
                        <p>
                        Indien door Vonnisbank of door Vonnisbank ingeschakelde derden in het kader van de opdracht werkzaamheden worden verricht op de locatie van de Contractspartij of een door de Contractspartij aangewezen locatie, draagt de Contractspartij kosteloos zorg voor de door die medewerkers in redelijkheid gewenste faciliteiten.</p>
                        <p>
                        Levering van zaken geschiedt af bedrijf van Vonnisbank. De Contractspartij is verplicht het geleverde af te nemen op het moment dat het geleverde hem ter beschikking worden gesteld. Indien de Contractspartij afname van door Vonnisbank geleverde zaken weigert of nalatig is met het verstrekken van informatie of instructies die noodzakelijk zijn voor de levering, dan is Vonnisbank gerechtigd de zaken op te slaan voor rekening en risico van de Contractspartij. Het risico van verlies, beschadiging of waardevermindering gaat op de Contractspartij over op het moment waarop zaken aan de Contractspartij ter beschikking staan.</p>
                        <p>
                        Vonnisbank is gerechtigd de overeenkomst in verschillende fasen uit te voeren en het aldus uitgevoerde gedeelte afzonderlijk te factureren.</p>
                        <p>
                        Indien de overeenkomst in fasen wordt uitgevoerd kan Vonnisbank de uitvoering van die onderdelen die tot een volgende fase behoren opschorten totdat de Contractspartij de resultaten van de daaraan voorafgaande fase schriftelijk heeft goedgekeurd.</p>
                        <p>
                        De Contractspartij draagt er zorg voor dat alle gegevens, waarvan Vonnisbank aangeeft dat deze noodzakelijk zijn of waarvan de Contractspartij redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de overeenkomst, tijdig aan Vonnisbank worden verstrekt. Indien de voor de uitvoering van de overeenkomst benodigde gegevens niet tijdig aan Vonnisbank zijn verstrekt, heeft Vonnisbank het recht de uitvoering van de overeenkomst op te schorten en / of de uit de vertraging voortvloeiende extra kosten volgens de alsdan gebruikelijke tarieven aan de Contractspartij in rekening te brengen. De uitvoeringstermijn vangt niet eerder aan dan nadat de Contractspartij de gegevens aan Vonnisbank ter beschikking heeft gesteld. Vonnisbank is niet aansprakelijk voor schade, van welke aard ook, doordat Vonnisbank is uitgegaan van door de Contractspartij verstrekte onjuiste en / of onvolledige gegevens.</p>
                        <p>
                        Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering daarvan noodzakelijk is om deze te wijzigen of aan te vullen, dan zullen partijen tijdig en in onderling overleg tot aanpassing van de overeenkomst overgaan. Indien de aard, omvang of inhoud van de overeenkomst, al dan niet op verzoek of aanwijzing van de Contractspartij, van de bevoegde instanties et cetera, wordt gewijzigd en de overeenkomst daardoor in kwalitatief en / of kwantitatief opzicht wordt gewijzigd, dan kan dit consequenties hebben voor hetgeen oorspronkelijk overeengekomen werd. Daardoor kan ook het oorspronkelijk overeengekomen bedrag worden verhoogd of verlaagd. Vonnisbank zal daarvan zoveel als mogelijk vooraf prijsopgaaf doen. Door een wijziging van de overeenkomst kan voorts de oorspronkelijk opgegeven termijn van uitvoering worden gewijzigd. De Contractspartij aanvaardt de mogelijkheid van wijziging van de overeenkomst, daaronder begrepen de wijziging in prijs en termijn van uitvoering.</p>
                        <p>
                        Indien de overeenkomst wordt gewijzigd, daaronder begrepen een aanvulling, dan is Vonnisbank gerechtigd om daaraan eerst uitvoering te geven nadat daarvoor akkoord is gegeven door de binnen Vonnisbank bevoegde persoon en de Contractspartij akkoord is gegaan met de voor de uitvoering opgegeven prijs en andere voorwaarden, daaronder begrepen het alsdan te bepalen tijdstip waarop daaraan uitvoering gegeven zal worden. Het niet of niet onmiddellijk uitvoeren van de gewijzigde overeenkomst levert geen wanprestatie van Vonnisbank op en is voor de Contractspartij geen grond om de overeenkomst op te zeggen of te annuleren.</p>
                        <p>
                        Zonder daarmee in gebreke te komen, kan Vonnisbank een verzoek tot wijziging van de overeenkomst weigeren, indien dit in kwalitatief en / of kwantitatief opzicht gevolg zou kunnen hebben bijvoorbeeld voor de in dat kader te verrichten werkzaamheden of te leveren zaken.</p>
                        <p>
                        De Contractspartij is steeds gehouden tot strikte nakoming van de op hem rustende verplichtingen uit hoofde van de overeenkomst met Vonnisbank.</p>
                        <p>
                        Bij niet deugdelijke nakoming door de Contractspartij, staat het Vonnisbank vrij om de Contractspartij de toegang tot haar producten tijdelijk of definitief, geheel of gedeeltelijk te ontzeggen en de verzending van gegevens of zaken op te schorten en / of te staken. Indien Vonnisbank gebruik maakt van deze bevoegdheid dan laat dit onverlet de verplichting tot nakoming door de Contractspartij van de op hem rustende verplichtingen jegens Vonnisbank en voorts de eventuele gehoudenheid tot vergoeding van alle schade, daaronder begrepen kosten, aan de zijde van Vonnisbank door de niet deugdelijke nakoming door de Contractspartij ontstaan of die daaruit voortvloeit.</p>
                        <p>
                        Vonnisbank is gerechtigd om naar eigen inzicht het gebruik van haar producten of de levering van zaken te weigeren.</p>
                        <p>
                        Indien de Contractspartij in gebreke mocht komen in de deugdelijke nakoming van hetgeen waartoe hij jegens Vonnisbank gehouden is, dan is de Contractspartij aansprakelijk voor alle schade aan de zijde van Vonnisbank daardoor direct of indirect ontstaan.</p>
                        <p>
                        Indien Vonnisbank met de Contractspartij een vast tarief of vaste prijs overeenkomt, dan is Vonnisbank niettemin te allen tijde gerechtigd tot verhoging van dit tarief of deze prijs zonder dat de Contractspartij in dat geval gerechtigd is om de overeenkomst om die reden te ontbinden, indien de verhoging van de prijs voortvloeit uit een bevoegdheid of verplichting ingevolge de wet- of regelgeving of haar oorzaak vindt in een stijging van de prijs van grondstoffen, lonen et cetera of op andere gronden die bij het aangaan van de overeenkomst redelijkerwijs niet voorzienbaar waren.</p>
                        <p>
                        Indien de prijsstijging anders dan als gevolg van een wijziging van de overeenkomst meer bedraagt dan 10% en plaatsvindt binnen drie maanden na het sluiten van de overeenkomst, dan is uitsluitend de Contractspartij die een beroep toekomt op titel 5 afdeling 3 van Boek 6 BW gerechtigd de overeenkomst door een schriftelijke verklaring te ontbinden, tenzij Vonnisbank</p>
                        <p>
                        Als dan alsnog bereid is om de overeenkomst op basis van het oorspronkelijk overeengekomene uit te voeren</p>
                        <p>
                        Indien de prijsverhoging voortvloeit uit een bevoegdheid of een op Vonnisbank rustende verplichting ingevolge de wet</p>
                        <p>
                        Indien bedongen is dat de aflevering langer dan drie maanden na de totstandkoming van de overeenkomst zal plaatsvinden</p>
                        <p>
                        Of, bij levering van een zaak, indien is bedongen dat de aflevering langer dan drie maanden na de koop zal plaatsvinden.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 4 OPSCHORTING, ONTBINDING EN TUSSENTIJDSE OPZEGGING VAN DE OVEREENKOMST</p>
                        <p>
                        Vonnisbank is bevoegd de nakoming van de verplichtingen op te schorten of de overeenkomst te ontbinden, indien de Contractspartij de verplichtingen uit de overeenkomst niet, niet volledig of niet tijdig nakomt, na het sluiten van de overeenkomst Vonnisbank ter kennis gekomen omstandigheden goede grond geven te vrezen dat de Contractspartij de verplichtingen niet zal nakomen, indien de Contractspartij bij het sluiten van de overeenkomst verzocht is om zekerheid te stellen voor de voldoening van zijn verplichtingen uit de overeenkomst en deze zekerheid uitblijft of onvoldoende is of indien door de vertraging aan de zijde van de Contractspartij niet langer van Vonnisbank kan worden gevergd dat hij de overeenkomst tegen de oorspronkelijk overeengekomen condities zal nakomen.</p>
                        <p>
                        Voorts is Vonnisbank bevoegd de overeenkomst te ontbinden indien zich omstandigheden voordoen welke van dien aard zijn dat nakoming van de overeenkomst onmogelijk is of indien er zich anderszins omstandigheden voordoen die van dien aard zijn dat ongewijzigde instandhouding van de overeenkomst in redelijkheid niet van Vonnisbank kan worden gevergd.</p>
                        <p>
                        Vonnisbank is gerechtigd om de overeenkomst ter stond te beëindigen indien een dienst om welke reden dan ook niet langer door haar wordt geleverd.</p>
                        <p>
                        Indien de overeenkomst wordt ontbonden zijn de vorderingen van Vonnisbank op de Contractspartij onmiddellijk opeisbaar. Indien Vonnisbank de nakoming van de verplichtingen opschort, behoudt hij zijn aanspraken uit de wet en overeenkomst.</p>
                        <p>
                        Indien Vonnisbank tot opschorting of ontbinding overgaat, is hij op generlei wijze gehouden tot vergoeding van schade en kosten daardoor op enigerlei wijze ontstaan.</p>
                        <p>
                        Indien de ontbinding aan de Contractspartij toerekenbaar is, is Vonnisbank gerechtigd tot vergoeding van de schade, daaronder begrepen de kosten, daardoor direct en indirect ontstaan.</p>
                        <p>
                        Indien de Contractspartij zijn uit de overeenkomst voortvloeiende verplichtingen niet nakomt en deze niet-nakoming ontbinding rechtvaardigt, dan is Vonnisbank gerechtigd de overeenkomst terstond en met directe ingang te ontbinden zonder enige verplichting zijnerzijds tot betaling van enige schadevergoeding of schadeloosstelling, terwijl de Contractspartij, uit hoofde van wanprestatie, wél tot schadevergoeding of schadeloosstelling is verplicht.</p>
                        <p>
                        Indien de overeenkomst tussentijds wordt opgezegd door Vonnisbank, zal Vonnisbank in overleg met de Contractspartij zorgdragen voor overdracht van nog te verrichten werkzaamheden aan derden. Dit tenzij de opzegging aan de Contractspartij toerekenbaar is. Indien de overdracht van de werkzaamheden voor Vonnisbank extra kosten met zich meebrengt, dan worden deze aan de Contractspartij in rekening gebracht. De Contractspartij is gehouden deze kosten binnen de daarvoor genoemde termijn te voldoen, tenzij Vonnisbank anders aangeeft.</p>
                        <p>
                        In geval van liquidatie, van (aanvrage van) surseance van betaling of faillissement, van beslaglegging - indien en voor zover het beslag niet binnen drie maanden is opgeheven - ten laste van de Contractspartij, van schuldsanering of een andere omstandigheid waardoor de Contractspartij niet langer vrijelijk over zijn vermogen kan beschikken, staat het Vonnisbank vrij om de overeenkomst terstond en met directe ingang op te zeggen danwel de order of overeenkomst te annuleren, zonder enige verplichting zijnerzijds tot betaling van enige schadevergoeding of schadeloosstelling. De vorderingen van Vonnisbank op de Contractspartij zijn in dat geval onmiddellijk opeisbaar.</p>
                        <p>
                        Indien de Contractspartij een geplaatste order geheel of gedeeltelijk annuleert, dan zullen de werkzaamheden die werden verricht en de daarvoor bestelde of gereedgemaakte zaken, vermeerderd met de eventuele aan- afvoer- en afleveringskosten daarvan en de voor de uitvoering van de overeenkomst gereserveerde arbeidstijd, integraal aan de Contractspartij in rekening worden gebracht.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 5 OVERMACHT</p>
                        <p>
                        Vonnisbank is niet gehouden tot het nakomen van enige verplichting jegens de Contractspartij indien zij daartoe gehinderd wordt als gevolg van een omstandigheid die niet is te wijten aan schuld, en noch krachtens de wet, een rechtshandeling of in het verkeer geldende opvattingen voor haar rekening komt.</p>
                        <p>
                        Onder overmacht wordt in deze algemene voorwaarden verstaan, naast hetgeen daaromtrent in de wet en jurisprudentie wordt begrepen, alle van buitenkomende oorzaken, voorzien of niet-voorzien, waarop Vonnisbank geen invloed kan uitoefenen, doch waardoor Vonnisbank niet in staat is haar verplichtingen na te komen. Werkstakingen in het bedrijf van Vonnisbank of van derden daaronder begrepen. Vonnisbank heeft ook het recht zich op overmacht te beroepen indien de omstandigheid die (verdere) nakoming van de overeenkomst verhindert, intreedt nadat Vonnisbank haar verbintenis had moeten nakomen.</p>
                        <p>
                        Vonnisbank kan gedurende de periode dat de overmacht voortduurt de verplichtingen uit de overeenkomst opschorten. Indien deze periode langer duurt dan twee maanden, dan is ieder der partijen gerechtigd de overeenkomst te ontbinden, zonder verplichting tot vergoeding van schade aan de andere partij.</p>
                        <p>
                        Voorzoveel Vonnisbank ten tijde van het intreden van overmacht haar verplichtingen uit de overeenkomst inmiddels gedeeltelijk is nagekomen of deze zal kunnen nakomen, en aan het nagekomen respectievelijk na te komen gedeelte zelfstandige waarde toekomt, is Vonnisbank gerechtigd om het reeds nagekomen respectievelijk na te komen gedeelte separaat te factureren. De Contractspartij is gehouden deze factuur te voldoen als ware er sprake van een afzonderlijke overeenkomst.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 6 BETALING EN INCASSOKOSTEN</p>
                        <p>
                        Betaling dient vooraf steeds te geschieden, op een door Vonnisbank aan te geven wijze in de valuta waarin is gefactureerd, tenzij schriftelijk anders door Vonnisbank aangegeven. Vonnisbank is gerechtigd om periodiek te factureren.</p>
                        <p>
                        Indien de Contractspartij in gebreke blijft in de tijdige betaling van een factuur, dan is de Contractspartij van rechtswege in verzuim. De Contractspartij is alsdan een rente verschuldigd van 1% per maand, tenzij de wettelijke rente hoger is, in welk geval de wettelijke rente verschuldigd is. De rente over het opeisbare bedrag zal worden berekend vanaf het moment dat de Contractspartij in verzuim is tot het moment van voldoening van het volledig verschuldigde bedrag.</p>
                        <p>
                        Vonnisbank heeft het recht de door Contractspartij gedane betalingen te laten strekken in de eerste plaats in mindering van de kosten, vervolgens in mindering van de opengevallen rente en tenslotte in mindering van de hoofdsom en de lopende rente. Vonnisbank kan, zonder daardoor in verzuim te komen, een aanbod tot betaling weigeren, indien de Contractspartij een andere volgorde voor de toerekening van de betaling aanwijst. Vonnisbank kan volledige aflossing van de hoofdsom weigeren, indien daarbij niet eveneens de opengevallen en lopende rente en incassokosten worden voldaan.</p>
                        <p>
                        De Contractspartij is nimmer gerechtigd tot verrekening van het door hem aan Vonnisbank verschuldigde. Bezwaren tegen de hoogte van een factuur schorten de betalingsverplichting niet op. De Contractspartij die geen beroep toekomt op afdeling 6.5.3 (de artikelen 231 tot en met 247 boek 6 BW) is evenmin gerechtigd om de betaling van een factuur om een andere reden op te schorten.</p>
                        <p>
                        Indien de Contractspartij in gebreke of in verzuim is in de (tijdige) nakoming van zijn verplichtingen, dan komen alle redelijke kosten ter verkrijging van voldoening buiten rechte voor rekening van de Contractspartij. Het verzuim van de Contractspartij die een natuurlijk persoon is, die niet handelt in de uitoefening van een beroep of bedrijf (particuliere Contractspartij), treedt in nadat hij is aangemaand tot betaling binnen veertien dagen na de dag van aanmaning en betaling uitblijft. In de aanmaning wordt eveneens aangegeven wat de gevolgen van het uitblijven van betaling zijn. De buitengerechtelijke kosten worden berekend op basis van hetgeen in de Nederlandse incassopraktijk gebruikelijk is, momenteel de berekeningsmethode volgens Rapport Voorwerk II. Indien Vonnisbank echter hogere kosten ter incasso heeft gemaakt die redelijkerwijs noodzakelijk waren en de Contractspartij geen natuurlijk persoon is die niet handelt in de uitoefening van een beroep of bedrijf (zakelijke Contractspartij), komen de werkelijk gemaakte kosten voor vergoeding in aanmerking. De eventuele gemaakte gerechtelijke en executiekosten zullen eveneens op de Contractspartij worden verhaald. De Contractspartij is over de verschuldigde incassokosten eveneens rente verschuldigd.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 7 EIGENDOMSVOORBEHOUD</p>
                        <p>
                        Indien er sprake is van eigendomsoverdracht door Vonnisbank aan de Contractspartij, dan blijft het door Vonnisbank in het kader van de overeenkomst geleverde eigendom van Vonnisbank totdat de Contractspartij alle verplichtingen uit de met Vonnisbank gesloten overeenkomst(en) deugdelijk is nagekomen.</p>
                        <p>
                        Het door Vonnisbank geleverde, dat ingevolge artikel 7 onder het eigendomsvoorbehoud valt, mag niet worden doorverkocht en mag nimmer als betaalmiddel worden gebruikt. De Contractspartij is niet bevoegd om het onder het eigendomsvoorbehoud vallende te verpanden of op enige andere wijze te bezwaren.</p>
                        <p>
                        De Contractspartij dient steeds al hetgeen te doen dat redelijkerwijs van hem verwacht mag worden om de eigendomsrechten van Vonnisbank veilig te stellen. Indien derden beslag leggen op het onder eigendomsvoorbehoud geleverde danwel rechten daarop willen vestigen of doen gelden, dan is de Contractspartij verplicht om Vonnisbank daarvan onmiddellijk op de hoogte te stellen. Voorts verplicht de Contractspartij zich om het onder eigendomsvoorbehoud geleverde te verzekeren en verzekerd te houden tegen brand, ontploffings- en waterschade alsmede tegen diefstal en de polis van deze verzekering op eerste verzoek aan Vonnisbank ter inzage te geven. Bij een eventuele uitkering van de verzekering is Vonnisbank gerechtigd tot deze penningen. Voorzoveel als nodig verbindt de Contractspartij zich er jegens Vonnisbank bij voorbaat toe om zijn medewerking te verlenen aan al hetgeen dat in dat kader nodig of wenselijk mocht (blijken) te zijn.</p>
                        <p>
                        Voor het geval Vonnisbank haar in dit artikel aangeduide eigendomsrechten wil uitoefenen, geeft de Contractspartij bij voorbaat onvoorwaardelijke en niet herroepelijke toestemming aan Vonnisbank en door Vonnisbank aan te wijzen derden om al die plaatsen te betreden waar de eigendommen van Vonnisbank zich bevinden en deze terug te nemen.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 8 GARANTIES, ONDERZOEK EN RECLAMES, VERJARINGSTERMIJN</p>
                        <p>
                        De door Vonnisbank te leveren zaken voldoen aan de gebruikelijke eisen en normen die daaraan op het moment van levering redelijkerwijs gesteld kunnen worden en waarvoor zij bij normaal gebruik in Nederland zijn bestemd. De in dit artikel genoemde garantie is van toepassing op zaken die bestemd zijn voor het gebruik binnen Nederland. Bij gebruik buiten Nederland dient de Contractspartij zelf te verifiëren of het gebruik daarvan geschikt is voor het gebruik aldaar en voldoen aan de voorwaarden die daaraan gesteld worden. Vonnisbank kan in dat geval andere garantie- en andere voorwaarden stellen ter zake van de te leveren zaken of uit te voeren werkzaamheden.</p>
                        <p>
                        De in dit artikel genoemde garantie geldt voor een periode van 12 maanden na levering, tenzij uit de aard van het geleverde anders voortvloeit of partijen anders zijn overeengekomen. Indien de door Vonnisbank verstrekte garantie een zaak betreft die door een derde werd geproduceerd, dan is de garantie beperkt tot die, die door de producent van de zaak ervoor wordt verstrekt, tenzij anders wordt vermeld.</p>
                        <p>
                        Iedere vorm van garantie komt te vervallen indien een gebrek is ontstaan als gevolg van of voortvloeit uit onoordeelkundig of oneigenlijk gebruik daarvan of gebruik na de houdbaarheidsdatum, onjuiste opslag of onderhoud daaraan door de Contractspartij en / of door derden wanneer, zonder schriftelijke toestemming van Vonnisbank, de Contractspartij of derden aan de zaak wijzigingen hebben aangebracht danwel hebben getracht aan te brengen, daaraan andere zaken werden bevestigd die daaraan niet bevestigd dienen te worden of indien deze werden ver- of bewerkt op een andere dan de voorgeschreven wijze. De Contractspartij komt evenmin aanspraak op garantie toe indien het gebrek is ontstaan door of het gevolg is van omstandigheden waar Vonnisbank geen invloed op kan uitoefenen, daaronder begrepen weersomstandigheden (zoals bijvoorbeeld doch niet uitsluitend, extreme regenval of temperaturen) et cetera.</p>
                        <p>
                        De Contractspartij is gehouden het geleverde te (doen) onderzoeken, onmiddellijk op het moment dat de zaken hem ter beschikking worden gesteld respectievelijk de desbetreffende werkzaamheden zijn uitgevoerd. Daarbij behoort de Contractspartij te onderzoeken of kwaliteit en/of kwantiteit van het geleverde overeenstemt met hetgeen is overeengekomen en voldoet aan de eisen die partijen dienaangaande zijn overeengekomen. Eventuele zichtbare gebreken dienen binnen zeven dagen na levering schriftelijk aan Vonnisbank te worden gemeld. Eventuele niet zichtbare gebreken dienen terstond, doch in ieder geval uiterlijk binnen veertien dagen, na ontdekking daarvan, schriftelijk aan Vonnisbank te worden gemeld. De melding dient een zo gedetailleerd mogelijke omschrijving van het gebrek te bevatten, zodat Vonnisbank in staat is adequaat te reageren. De Contractspartij dient Vonnisbank in de gelegenheid te stellen een klacht te (doen) onderzoeken.</p>
                        <p>
                        Indien de Contractspartij tijdig reclameert, schort dit zijn betalingsverplichting niet op. De Contractspartij blijft in dat geval ook gehouden tot afname en betaling van de overigens bestelde zaken en hetgeen waartoe hij Vonnisbank opdracht gegeven heeft.</p>
                        <p>
                        Indien van een gebrek later melding wordt gemaakt, dan komt de Contractspartij geen recht meer toe op herstel, vervanging of schadeloosstelling.</p>
                        <p>
                        Indien vaststaat dat een zaak gebrekkig is en dienaangaande tijdig is gereclameerd, dan zal Vonnisbank de gebrekkige zaak binnen redelijke termijn na retourontvangst daarvan danwel, indien retournering redelijkerwijze niet mogelijk is, schriftelijke kennisgeving ter zake van het gebrek door de Contractspartij, ter keuze van Vonnisbank, vervangen of zorgdragen voor herstel daarvan danwel vervangende vergoeding daarvoor aan de Contractspartij voldoen. In geval van vervanging is de Contractspartij gehouden om de vervangen zaak aan Vonnisbank te retourneren en de eigendom daarover aan Vonnisbank te verschaffen, tenzij Vonnisbank anders aangeeft.</p>
                        <p>
                        Indien komt vast te staan dat een klacht ongegrond is, dan komen de kosten daardoor ontstaan, daaronder begrepen de onderzoekskosten, aan de zijde van Vonnisbank daardoor gevallen, integraal voor rekening van de Contractspartij.</p>
                        <p>
                        Na verloop van de garantietermijn zullen alle kosten voor herstel of vervanging, inclusief administratie-, verzend- en voorrijdkosten, aan de Contractspartij in rekening gebracht worden.</p>
                        <p>
                        In afwijking van de wettelijke verjaringstermijnen, bedraagt de verjaringstermijn van alle vorderingen en verweren jegens Vonnisbank en de door Vonnisbank bij de uitvoering van een overeenkomst betrokken derden, één jaar.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 9 AANSPRAKELIJKHEID</p>
                        <p>
                        Indien Vonnisbank aansprakelijk mocht zijn, dan is deze aansprakelijkheid beperkt tot hetgeen in deze bepaling is geregeld.</p>
                        <p>
                        Vonnisbank is niet aansprakelijk voor schade, van welke aard ook, ontstaan doordat Vonnisbank is uitgegaan van door of namens de Contractspartij verstrekte onjuiste en / of onvolledige gegevens.</p>
                        <p>
                        Indien Vonnisbank aansprakelijk mocht zijn voor enigerlei schade, dan is de aansprakelijkheid van Vonnisbank beperkt tot maximaal tweemaal de factuurwaarde van de order, althans tot dat gedeelte van de order waarop de aansprakelijkheid betrekking heeft.</p>
                        <p>
                        De aansprakelijkheid van Vonnisbank is in ieder geval steeds beperkt tot het bedrag der uitkering van zijn verzekeraar in voorkomend geval.</p>
                        <p>
                        Vonnisbank is uitsluitend aansprakelijk voor directe schade.</p>
                        <p>
                        Onder directe schade wordt uitsluitend verstaan de redelijke kosten ter vaststelling van de oorzaak en de omvang van de schade, voor zover de vaststelling betrekking heeft op schade in de zin van deze voorwaarden, de eventuele redelijke kosten gemaakt om de gebrekkige prestatie van Vonnisbank aan de overeenkomst te laten beantwoorden, voor zoveel deze aan Vonnisbank toegerekend kunnen worden en redelijke kosten, gemaakt ter voorkoming of beperking van schade, voor zover de Contractspartij aantoont dat deze kosten hebben geleid tot beperking van directe schade als bedoeld in deze algemene voorwaarden. Vonnisbank is nimmer aansprakelijk voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst, gemiste besparingen en schade door bedrijfsstagnatie.</p>
                        <p>
                        In afwijking van de wettelijke verjaringstermijnen, bedraagt de verjaringstermijn van alle vorderingen en verweren van een Contractspartij jegens Vonnisbank en de door Vonnisbank betrokken derden een jaar.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 10 VRIJWARING</p>
                        <p>
                        De Contractspartij vrijwaart Vonnisbank voor eventuele aanspraken van derden, die in verband met de uitvoering van de overeenkomst schade lijden en waarvan de oorzaak aan andere dan aan Vonnisbank toerekenbaar is. Indien Vonnisbank uit dien hoofde door derden mocht worden aangesproken, dan is de Contractspartij gehouden Vonnisbank zowel buiten als in rechte bij te staan en onverwijld al hetgeen te doen dat van hem in dat geval verwacht mag worden. Mocht de Contractspartij in gebreke blijven in het nemen van adequate maatregelen, dan is Vonnisbank, zonder ingebrekestelling, gerechtigd zelf daartoe over te gaan. Alle kosten en schade aan de zijde van Vonnisbank en derden daardoor ontstaan, komen integraal voor rekening en risico van de Contractspartij.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 11 INTELLECTUELE EIGENDOM</p>
                        <p>
                        Vonnisbank behoudt zich de rechten en bevoegdheden voor die haar toekomen op grond van de Auteurswet en andere intellectuele wet- en regelgeving. Vonnisbank heeft het recht de door de uitvoering van een overeenkomst aan haar zijde toegenomen kennis ook voor andere doeleinden te gebruiken, voorzover hierbij geen strikt vertrouwelijke informatie van de Contractspartij ter kennis van derden wordt gebracht.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 12 HET PRODUCT VONNISBANK</p>
                        <p>
                        Vonnisbank is uitgever van het product Vonnisbank.</p>
                        <p>
                        Het product Vonnisbank, hierna te noemen Het Product, is een webapplicatie. Het doel van Het Product is dat eigenaren van een gerechtelijke einduitspraak of hun gemachtigde de einduitspraak in een gerechtelijke procedure waar zij zelf als procespartij bij betrokken zijn, op de website van Vonnisbank kunnen plaatsen. Het plaatsen van een gerechtelijke einduitspraak dient steeds plaats te vinden op door Vonnisbank te bepalen wijze. Van de gerechtelijke uitspraak worden door Vonnisbank te selecteren gedeelten via de website van Vonnisbank openbaar gemaakt teneinde andere bezoekers van de website van Vonnisbank daarvan kennis te kunnen laten nemen. Vonnisbank stelt de voorwaarden vast waaronder dergelijke uitspraken geplaatst worden en wanneer tijdelijke of definitieve verwijdering plaatsvindt. Vonnisbank is gerechtigd om deze voorwaarden tussentijds eenzijdig te wijzigen, zonder dat zij in dat geval gehouden is tot enigerlei vergoeding of compensatie aan de Contractspartij of aan derden.</p>
                        <p>
                        Het is een gebruiker van Het Product niet toegestaan om Het Product voor een ander doel te gebruiken dan waarvoor het bestemd is. Bij gebruik van Het Product voor andere doeleinden dan waarvoor Het Product is bedoeld, is Vonnisbank bevoegd om de Contractspartij van Vonnisbank uit het systeem te verwijderen en de overeenkomst met onmiddellijke ingang te ontbinden, zonder dat daardoor voor haar een verplichting ontstaat tot compensatie of schadeloosstelling terwijl de Contractspartij in dat geval wel gehouden is tot vergoeding van alle schade hoe ook genaamd die uit de niet nakoming voortvloeit. De Contractspartij zal zich onthouden van het doen van onbehoorlijke uitlatingen, het plaatsen van onbehoorlijke foto's en andere uitingen. Voorts is het niet toegestaan zich voor te doen als een ander of om zich op een andere wijze te presenteren dan die die overeenkomt met de werkelijkheid en hoedanigheid, zowel ook op het gebied van kennis als op het gebied van persoonlijke eigenschappen en kwaliteiten.</p>
                        <p>
                        Indien een Contractspartij zich bedient van onbehoorlijke taal, onbehoorlijke foto's, onbehoorlijke video's of andere gegevens die niet in overeenstemming zijn met de werkelijkheid of met hetgeen in het maatschappelijk verkeer betamelijk is, heeft Vonnisbank, zonder daarmee enige verplichting op zich te nemen daartoe gehouden te zijn, het recht om de gerechtelijke uitspraak te verwijderen en om overige gerechtelijke uitspraken van de Contractspartij niet te plaatsen respectievelijk eerst inhoudelijk te beoordelen. Indien Vonnisbank gebruik maakt van deze bevoegdheden dan heeft de Contractspartij of derden geen aanspraak op restitutie van reeds door hem betaalde abonnementsvergoedingen noch op compensatie of schadevergoeding. Indien Vonnisbank geen gebruik maakt van deze bevoegdheden dan komt zij daarmee niet in gebreke in de verplichtingen jegens gebruikers van het Product of derden. De Contractspartij die dergelijke gegevens opneemt is daarentegen wel verantwoordelijk en aansprakelijk en is gehouden tot vrijwaring van Vonnisbank voor eventuele claims van derden die daarmee verband houden of daaruit voortvloeien.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 13 HET GEBRUIK VAN GEGEVENS</p>
                        <p>
                        Een partij die zich bij Vonnisbank registreert staat in voor de juistheid van de gegevens die hij registreert. Vonnisbank is op geen enkele wijze aansprakelijk voor onjuistheden of onvolkomenheden in de gegevens. De partij die zich registreert is gehouden tot vrijwaring van Vonnisbank en tot vergoeding van de schade die uit de registratie van onjuiste gegevens voortvloeit.</p>
                        <p>
                        Het is de Contractspartij niet toegestaan om door of via Het Product hyperlinks of verwijzingen naar derden te maken die niet overeenkomen met het doel waarvoor Het Product bestemd is, daaronder begrepen verwijzingen naar pornografische, enigerlei naar algemene maatstaven lasterlijke of belastende, discriminerende of naar ras, geslacht, seksuele geaardheid of geloof onderscheid makende websites, blogs, profielen of andere uitingen.</p>
                        <p>
                        De gebruiker van Het Product is ermee bekend en aanvaardt onvoorwaardelijk dat de geregistreerde gegevens of een selectie daarvan openbaar gemaakt worden en dat deze overgenomen kunnen worden door derden. Vonnisbank is op geen enkele wijze aansprakelijk voor het openbaar maken of het niet openbaar maken van deze gegevens of het gebruik daarvan door derden.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 14 GEBRUIK GEGEVENS EN VERANTWOORDELIJKHEID CONTRACTSPARTIJ</p>
                        <p>
                        Voor het gebruik van Het Product Vonnisbank wordt ingelogd met een e-mailadres en een wachtwoord.</p>
                        <p>
                        De Contractspartij is verantwoordelijk voor de keuze van het e-mailadres en het wachtwoord.</p>
                        <p>
                        De Contractspartij is ermee bekend dat geheimhouding van het e-mailadres en het wachtwoord noodzakelijk is voor het gebruik van Het Product Vonnisbank en om toegang tot de gegevens van Vonnisbank door derden te voorkomen.</p>
                        <p>
                        Het is de Contractspartij niet toegestaan om deze gegevens aan derden te verstrekken anders dan ten behoeve van het beheer van het account van de Contractspartij. Indien er schade ontstaat door het openbaar maken van deze gegevens of het verlies van deze gegevens dan is dat de verantwoordelijkheid van de Contractspartij. Indien daardoor schade ontstaat aan de zijde van Vonnisbank of van derden dan is de Contractspartij gehouden tot vergoeding daarvan. Indien Vonnisbank aansprakelijk wordt gehouden voor schade die daardoor is ontstaan dan is de Contractspartij gehouden tot integrale vrijwaring van Vonnisbank.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 15 OVEREENKOMSTEN MET DERDEN</p>
                        <p>
                        Indien door of via Het Product Vonnisbank een overeenkomst tot stand komt met een of meerdere gebruikers van Het Product, dan is Vonnisbank nimmer aansprakelijk of verantwoordelijk voor de uitvoering van de overeenkomst, daaronder mede begrepen de voldoening van facturen. Vonnisbank is geen partij bij de dergelijke overeenkomsten.</p>
                        <p>
                        Vonnisbank is niet verantwoordelijk voor de deugdelijke overdracht van gegevens door of via Het Product. Vonnisbank aanvaardt geen enkele aansprakelijkheid voor het eventuele verlies, de beschadiging of het corrumperen van data of vertraging in de verzending daarvan.</p>
                        <p>
                        Vonnisbank stelt de identiteit van een gebruiker niet vast, controleert de juistheid of volledigheid van door hem opgegeven gegevens niet en staat dus niet in voor de juistheid of volledigheid van door een gebruiker opgegeven gegevens.</p>
                        <p>
                        Vonnisbank is gerechtigd om te harer keuze en zonder nadere motivering, een profiel of een gedeelte daarvan, daaronder begrepen einduitspraken, te verwijderen en verwijdert te houden uit Het Product.</p>
                        <p>
                        De Contractspartij is gehouden tot vrijwaring van Vonnisbank voor claims hoe ook genaamd die verband houden met of voortvloeien uit rechtsverhoudingen met derden die tot stand komen door of via de gebruikmaking van Het Product.</p>
                        <p>
                        Vonnisbank controleert de door gebruikers van Het Product niet. Indien zij dit op enig moment wel mocht doen, dan is zij daartoe niet gehouden en is zij niet aansprakelijk voor eventuele daarin opgenomen onjuiste, onvolledige of onbehoorlijke gegevens of verwijzingen en de nadelige gevolgen daarvan. De partij die dergelijke gegevens opneemt is daarentegen wel verantwoordelijk en aansprakelijk en is gehouden tot vrijwaring van Vonnisbank voor eventuele claims van derden die daarmee verband houden of daaruit voortvloeien.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 16 GEGEVENS CONTRACTSPARTIJ</p>
                        <p>
                        De door de Contractspartij geregistreerde gegevens worden opgeslagen in een DataBase. Deze gegevens worden door Vonnisbank gebruikt voor het functioneren van Het Product. Deze gegevens kunnen geheel of gedeeltelijk zichtbaar en beschikbaar en deze kunnen worden doorzocht door derden. De Contractspartij is daarmee akkoord en geeft daartoe toestemming door registratie op de website van Vonnisbank.</p>
                        <p>
                        De Contractspartij staat er voor in dat de door hem ingevoerde gegevens juist zijn, dat hij daarmee of daardoor geen inbreuk maakt op rechten van derden en dat deze niet misleidend zijn. De door de Contractspartij ingevoerde gegevens worden niet gecontroleerd door Vonnisbank. Vonnisbank is niet verantwoordelijk voor de juistheid en volledigheid daarvan en aanvaardt geen aansprakelijkheid voor eventuele schade die uit het gebruik van deze gegevens voortvloeit. De Contractspartij is gehouden tot vrijwaring van Vonnisbank indien Vonnisbank wordt aangesproken door derden in verband met door de Contractspartij ingevoerde gegevens. Vonnisbank is in dat geval gerechtigd de overeenkomst te ontbinden, zonder daardoor gehouden te zijn tot voldoening van enigerlei vergoeding of compensatie aan de Contractspartij. Indien Vonnisbank niet overgaat tot ontbinding, dan is de Contractspartij gehouden de gegevens zodanig te wijzigen dat deze alsnog voldoen aan het bepaalde in artikel 9. Vonnisbank is gerechtigd om de gegevens niet eerder vrij te geven dan nadat deze gewijzigd zijn. De Contractspartij blijft in dat geval gehouden om de gebruikersvergoeding te voldoen.</p>
                        <p>
                        Vonnisbank is gerechtigd de juistheid van ingevoerde gegevens te controleren en daartoe met derden contact op te nemen. Bij ontdekking van mogelijk niet juiste of onvolledig ingevoerde gegevens en/of bij het vermoeden van inbreuk op de intellectuele eigendoms- en andere rechten van derden behoudt Vonnisbank zich het recht voor deze gegevens te verwijderen en/of alle van de betreffende persoon of organisatie ingevoerde gegevens te verwijderen.</p>
                        <p>
                        Vonnisbank aanvaardt geen aansprakelijkheid voor verlies, beschadiging of wijziging van ingevoerde gegevens, tenzij er sprake is van opzet of bewuste roekeloosheid van de zijde van Vonnisbank.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 17 GEBRUIK VAN HET PRODUCT, BOETE</p>
                        <p>
                        Het is de Contractspartij niet toegestaan om Het Product en de daarin opgenomen gegevens en functionaliteiten, voor andere doeleinden te gebruiken dan waarvoor het Product bestemd is en waarvoor de opgenomen gegevens en functionaliteiten bestemd zijn. Daarvan is onder andere maar niet uitsluitend sprake indien door de Contractspartij frauduleuze handelingen gepleegd worden zoals het niet registreren van een bestaand vonnis of indien door de Contractspartij een vonnis niet verwijderd wordt (op status gesloten zetten) terwijl hij dat wel moet doen gelet op de status van de zaak.</p>
                        <p>
                        Bij niet deugdelijke nakoming is de Contractspartij een direct opeisbare boete van € 1.000,00 per gebeurtenis, en van € 500,00 voor iedere dag dat deze overtreding voortduurt, verschuldigd, onverminderd het recht van Vonnisbank om vergoeding van schade die daaruit voortvloeit te vorderen.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 18 TOEPASSELIJK RECHT EN GESCHILLEN</p>
                        <p>
                        Op alle rechtsbetrekkingen waarbij Vonnisbank partij is, is uitsluitend het Nederlands recht van toepassing, ook indien aan een verbintenis geheel of gedeeltelijk in het buitenland uitvoering wordt gegeven of indien de bij de rechtsbetrekking betrokken partij aldaar woonplaats heeft. De toepasselijkheid van het Weens Koopverdrag wordt uitgesloten.</p>
                        <p>
                        De rechter in de vestigingsplaats van Vonnisbank is bij uitsluiting bevoegd van geschillen kennis te nemen, tenzij de wet dwingend anders voorschrijft. Niettemin heeft Vonnisbank het recht het geschil voor te leggen aan de volgens de wet bevoegde rechter.</p>
                        <p>
                        Partijen zullen eerst een beroep op de rechter doen nadat zij zich tot het uiterste hebben ingespannen een geschil in onderling overleg te beslechten.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        ARTIKEL 19 VINDPLAATS EN WIJZIGING VOORWAARDEN</p>
                        <p>
                        Deze voorwaarden zijn gedeponeerd bij de Kamer van Koophandel Oost Brabant.</p>
                        <p>
                        Van toepassing is steeds de laatst gedeponeerde versie c.q. de versie zoals die gold ten tijde van het tot stand komen van de rechtsbetrekking met Vonnisbank.</p>
                        <p>
                        De Nederlandse tekst van de algemene voorwaarden is steeds bepalend voor de uitleg daarvan.</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        Versie 31 oktober 2016</p>
                        <p>
                        &nbsp;</p>
                        <p>
                        &nbsp;</p>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;