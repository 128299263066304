import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

import overimg from '../../images/over_ons.webp';

function App() {
    return (
        <div className="Main">
             <Header />
             <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Over ons</h1>
                        </div>
                        <div className="flex">
                            <div className="basis-8/12">
                                <p>Beste bezoeker, beste klant,</p>
                                <p>Graag stel ik mezelf voor en deel graag de reden waarom ik met Vonnisbank.nl ben gestart.</p>
                                <p>
                                    Als ondernemer snappen wij de taal, passie en emoties van andere ondernemers. Vooral als facturen niet betaald worden 
                                    waar hard voor gewerkt is. Het voelt onrechtvaardig en gaat vaak gepaard met veel emotie en negatieve energie. Nadat er
                                    een geld- en tijdrovend incasso traject is doorlopen, frustreert het veel ondernemers en bedrijven dat er nog steeds niet betaald wordt.
                                </p>
                                <p>
                                    Hier is mijn missie uit ontstaan. Wij willen ondernemers en bedrijven behoeden voor opdrachtgevers en zakelijke klanten die hun factuur
                                    niet betalen, ondanks een gerechtelijke uitspraak. Wij willen bedrijven en ondernemers extra inzicht geven in het betalingsgedrag
                                    van hun bestaande en toekomstige klanten.
                                </p>
                                <p>Vonnisbank moest aan o.a. de volgende kernwaarden voldoen :</p>
                                <p>
                                    <u>Preventief</u>: Het waarschuwen van andere bedrijven om hen te behoeden voor slecht betalende bedrijven en ondernemers.
                                </p>
                                <p>
                                    <u>Rechtvaardig</u>: Het laten registeren en bekend maken van het vonnis. De rechter gaf de schuldeiser immers gelijk.
                                </p>
                                <p>
                                    <u>Kostenverlagend</u>: Voordat u nog meer kosten moet maken, brengen wij de schuldenaar op de hoogte van zijn registratie
                                    op Vonnisbank.nl. Dit doen wij per sms, email en per post. Wij adviseren de schuldenaar in deze notificatie dat hij direct
                                    contact moet opnemen met de schuldeiser en moet betalen. Doet hij dit niet dan blijft de registratie open staan. Zo voorkomen
                                    we in ieder geval nieuwe slachtoffers.
                                </p>
                                <p>Wij hopen u van dienst te kunnen zijn met Vonnisbank.nl.</p>
                                <p>Paul van Oers&nbsp;</p>
                            </div>
                            <div className="basis-4/12">
                                <img src={overimg} className="over-ons-img" alt="over ons afbeelding" />
                            </div>
                        </div>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;