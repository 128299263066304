import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer, FileInput } from '../../components/common';

import '../../styles/App.scss';

class VonnisEdit extends React.Component {

    state = {
        user: {},
        cocNumber: '',
        companyName: '',
        email: '',
        phone: '',
        vonnisDate: '',
        vonnisStatus: '',
        vonnisPaid: '',
        vonnisPrice: '',
        vonnisNumber: '',
        vonnisPdf: ''
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        this.checkVonnisRights();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    checkVonnisRights() {
        const url = window.location.pathname;
        const vonnisId = url.split('edit/').pop();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/vonnis/${this.state.user.id}/get/${vonnisId}`, {
            withCredentials: true,
        })
        .then(response => {
            if (response.status === 200) {
                var validDate = response.data.vonnisRights.vonnisDate.split('T')[0];

                console.log(response.data.vonnisRights);
                
                this.setState({
                    cocNumber: response.data.vonnisRights.cocNumber,
                    companyName: response.data.vonnisRights.companyName,
                    email: response.data.vonnisRights.email,
                    phone: response.data.vonnisRights.phone,
                    vonnisDate: validDate,
                    vonnisStatus: response.data.vonnisRights.vonnisStatus,
                    vonnisPaid: response.data.vonnisRights.vonnisPaid,
                    vonnisPrice: response.data.vonnisRights.vonnisPrice,
                    vonnisNumber: response.data.vonnisRights.vonnisNumber
                }, () => {
                    console.log(this.state.vonnisPaid);
                });
            } else {
                axios.get(`${baseUrl}/api/clearauth/`, {
                    withCredentials: true,
                })
                this.props.navigate('/login');
            }
        })
    }

    vonnisEdit (e) {
        e.preventDefault();

        const url = window.location.pathname;
        const vonnisId = url.split('edit/').pop();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.post(`${baseUrl}/api/vonnis/${this.state.user.id}/edit/${vonnisId}`, {
            cocNumber: this.state.cocNumber,
            companyName: this.state.companyName,
            email: this.state.email,
            phone: this.state.phone,
            vonnisDate: this.state.vonnisDate,
            vonnisStatus: this.state.vonnisStatus,
            vonnisPaid: this.state.vonnisPaid,
            vonnisPrice: this.state.vonnisPrice,
            vonnisNumber: this.state.vonnisNumber,
            vonnisPdf: this.state.vonnisPdf
        }, {
            withCredentials: true,
        })
        .then(response => {
            if (response.status === 200) {
                this.props.navigate('/vonnis/mijn');
            }
        })
        .then(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="main-container faq-page">
                <div className="page-intro">
                    <div className="container mx-auto">
                        <h1>Vonnis bewerken</h1>
                        <div className="intro">
                            <p>
                                U kunt hier de ingevoerde vonnissen bewerken. Indien een schuldenaar het vonnis betaald heeft,
                                 kunt u dit vonnis op 'Is betaald' zetten.
                            </p>
                            <p>
                                Als een schuldenaar in cassatie of in hoger beroep gaat kunt u hier het vonnis op 'Verbergen'
                                 zetten, zodat het vonnis niet meer getoond wordt in de raadplegen pagina.
                            </p>
                            <p>
                                Ook kunt u via deze pagina het vonnis in pdf formaat downloaden.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-pattern front">
                    <div className="main-container container mx-auto">
                        <div className="form-wrapper">

                            <div className="alert alert-warning duplicate-error hidden" role="alert">Vonnis zaaknummer is al bekend bij Vonnisbank.</div>

                            <form onSubmit={this.vonnisEdit.bind(this)}>
                                <div className="form-group">
                                    <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} type="text" placeholder="KvK-nummer" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})} type="text" placeholder="Handelend onder naam" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="text" placeholder="E-mailadres schuldenaar" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})} type="text" placeholder="Mobiel telefoonnummer schuldenaar" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisDate} onChange={(e) => this.setState({vonnisDate: e.target.value})} type="date" placeholder="Datum vonnis" className="form-control" required />
                                </div>
                                <div className='grid sm:grid-cols-12 sm:gap-2'>
                                    <div className="form-group sm:col-span-6 col-span-12">
                                        <select value={this.state.vonnisPaid} onChange={(e) => this.setState({vonnisPaid: e.target.value})} className="form-control" required>
                                            <option value={false}>Is open</option>
                                            <option value={true}>Is betaald</option>
                                        </select>
                                    </div>
                                    <div className="form-group sm:col-span-6 col-span-12">
                                        <select value={this.state.vonnisStatus} onChange={(e) => this.setState({vonnisStatus: e.target.value})} className="form-control" required>
                                            <option value={true}>Tonen</option>
                                            <option value={false}>Verborgen</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisPrice} onChange={(e) => this.setState({vonnisPrice: e.target.value})} type="text" placeholder="Gevorderd bedrag volgens vonnis" className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <input value={this.state.vonnisNumber} onChange={(e) => this.setState({vonnisNumber: e.target.value})} type="text" placeholder="Vonnis zaak nummer" className="form-control" required />
                                </div>
                                {
                                    this.state.user.trustedAccount ? null : <FileInput 
                                    value={this.state.vonnisNumber}
                                    onChange={(e) => this.setState({vonnisPdf: e.target.value}) }/>
                                }
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Vonnis opslaan"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
    
}

function WithNavigate() {
    const navigate = useNavigate();
    return <VonnisEdit navigate={navigate} />
}

export default WithNavigate;