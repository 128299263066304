import React, { createRef } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import axios from 'axios';

import '../../../styles/App.scss';
import '../../../styles/backend/blondedcms.scss';

class BlondedcmsUsers extends React.Component {

    constructor(props) {
        super(props);
        this.tableRef = createRef();
    }

    state = {
        //GET ACCOUNT INFO
        user: {},
        
        //GET VONNISBANK USERS
        allUsers: []
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user || user.role !== 'vonnisbank_admin') {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({ user: user });
                    this.getAllUsers();
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getAllUsers() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/admin/users/details`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ allUsers: response.data.users });
        })
        .catch(error => {
            console.log(error);
        });
    }

    deleteUser(e) {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        var closestUser = e.target.closest('.users').getAttribute('id');
        var closestUserElement = e.target.closest('.users');
        var closestUserEmail = closestUserElement.querySelector('.email').innerHTML;

        if (window.confirm("Gebruiker: " + closestUserEmail + " verwijderen?")) {
            axios.get(`${baseUrl}/api/admin/user/delete/` + closestUser,  {
                withCredentials: true,
            })
            .then(response => { 
                window.location.reload(false);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    buildUsersList(userData) {
        if (userData.length > 0) {
            return userData.map((userData, index) => (
                <tr key={index} className="users" id={userData._id}>
                  <td className="email">{userData.email}</td>
                  <td className="name">{userData.name}</td>
                  <td className="phone">{userData.phone}</td>
                  <td className="kvk">{userData.cocNumber}</td>
                  <td className="company">{userData.companyName}</td>
                  <td className="city">{userData.city}</td>
                  <td className="postcode">{userData.postalCode}</td>
                  <td className="street">{userData.address}</td>
                  <td className="client">{userData.customerOf}</td>
                  <td className="rel">{userData.customerRel}</td>
                  <td className="verified">{userData.verified ? 'Geverifieerd' : 'Ongeverifieerd'}</td>
                  <td className="role">{userData.gebruikersrol}</td>
                  <td className="subscriber">{userData.subscriberSince ? userData.subscriberSince.substring(0, userData.subscriberSince.length - 14) : ''}</td>
                  <td className="created">{userData.createdAt.substr(0, 10)}</td>
                  {userData.gebruikersrol !== "vonnisbank_admin" ? <td><button onClick={this.deleteUser.bind(this)} className="button btn-small bg-gray-900 hover:bg-white text-white hover:text-gray-900">Gebruiker verwijderen</button></td> : <td></td> }
                </tr>
            ));
        }
    }

    hideColumn(e) {
        var columns = document.querySelectorAll('.' + e.target.id);

        if (e.target.checked) {
            if (columns.length > 0) {
                columns.forEach(column => {
                    column.classList.add('hidden');
                });
            }
        } else {
            if (columns.length > 0) {
                columns.forEach(column => {
                    column.classList.remove('hidden');
                });
            }
        }
    }

    resetHidden() {
        var allTh = document.querySelectorAll('table th');
        var allTd = document.querySelectorAll('table td');
        var allCheckboxes = document.querySelectorAll('.hide-button-wrapper input');

        for (var i = 0; i < allTh.length; i++) {
            allTh[i].classList.remove('hidden');
        }

        for (var j = 0; j < allTd.length; j++) {
            allTd[i].classList.remove('hidden');
        }

        for (var k = 0; k < allCheckboxes.length; k++) {
            allCheckboxes[i].checked = false;
        }
    }
    
    render() {
        return (
            <div className="Main">
                 <div className="blondedcms">
                    <div className="flex flex-col items-center w-16 overflow-hidden blonded-sidebar">
                        <div className="flex items-center justify-center">
                            <svg className="sidebar-icon w-8 h-8 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z" />
                            </svg>
                        </div>
                        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
                            <a className="flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-gray-900 hover:text-white" href="/blondedcms/admin">
                                <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                                </svg>
                            </a>
                        </div>
                        
                        <a className="flex items-center justify-center w-16 h-16 mt-auto hover:bg-gray-900 hover:text-white" href="/account">
                            <svg className="w-6 h-6 stroke-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </a>
                    </div>
                    <div className="blonded-content">
                        <div className="cms-wrapper">
                            <div className="cms-header">
                                <h1 className="cms-title">Blonded CMS</h1>
                                <p className="cms-subtitle">Welkom {this.state.user.name}</p>
                            </div>
                            <div className="cms-body">
                                <h2 className="body-title">Vonnisbank gebruikers</h2>
                                <p className="body-subtitle">Volledige lijst met gebruikers.</p>

                                <div className="flex flex-row flex-wrap">
                                    <div className="basis-full">
                                        <DownloadTableExcel
                                            filename="gebuikers_tabel"
                                            sheet="users"
                                            currentTableRef={this.tableRef.current}
                                        >
                                            <button className="button btn-cms-primary">Exporteer gebuikers lijst</button>
                                        </DownloadTableExcel>
                                    </div>
                                </div>

                                <div className="flex flex-row flex-wrap">
                                    <div className="basis-full hide-table-row">
                                        <h3>Verberg kolommen</h3>
                                        <div className="hide-table-inputs flex flex-wrap justify-start gap-2">
                                            <div className="hide-button-wrapper">
                                                <label htmlFor="email">E-mailadres</label>
                                                <input type="checkbox" id="email" name="hide-email" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="name">Naam</label>
                                                <input type="checkbox" id="name" name="hide-name" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="phone">Telefoonnummer</label>
                                                <input type="checkbox" id="phone" name="hide-phone" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="kvk">KvK-nummer</label>
                                                <input type="checkbox" id="kvk" name="hide-kvk" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="company">Bedrijfsnaam</label>
                                                <input type="checkbox" id="company" name="hide-company" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="city">Stad</label>
                                                <input type="checkbox" id="city" name="hide-city" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="postcode">Postcode</label>
                                                <input type="checkbox" id="postcode" name="hide-postcode" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="street">Straat</label>
                                                <input type="checkbox" id="street" name="hide-street" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="client">Klant van</label>
                                                <input type="checkbox" id="client" name="hide-client" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="rel">Relatienummer</label>
                                                <input type="checkbox" id="rel" name="hide-client" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="role">Rol</label>
                                                <input type="checkbox" id="role" name="hide-role" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="verified">Geverifieerd</label>
                                                <input type="checkbox" id="verified" name="hide-verified" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper">
                                                <label htmlFor="created">Aangemaakt op</label>
                                                <input type="checkbox" id="created" name="hide-created" onClick={this.hideColumn} />
                                            </div>

                                            <div className="hide-button-wrapper spacer"></div>
                                            <div className="hide-button-wrapper">
                                                <input type="button" id="show-all" name="show-all" value="Reset" onClick={this.resetHidden} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-row flex-wrap scroll-table-row">
                                    <div className="basis-full">
                                        <div className="table-responsive admin-responsive">
                                            <table className="data-table admin-table" ref={this.tableRef}>
                                                <thead>
                                                    <tr>
                                                        <th className="email">E-mailadres</th>
                                                        <th className="name">Naam</th>
                                                        <th className="phone">Telefoonnummer</th>
                                                        <th className="kvk">KvK-nummer</th>
                                                        <th className="company">Bedrijfsnaam</th>
                                                        <th className="city">Stad</th>
                                                        <th className="postcode">Postcode</th>
                                                        <th className="street">Straat</th>
                                                        <th className="client">Klant van</th>
                                                        <th className="rel">Relatienummer</th>
                                                        <th className="verified">Geverifieerd</th>
                                                        <th className="role">Rol</th>
                                                        <th className="subscriber">Betalend lid per</th>
                                                        <th className="created">Aangemaakt op</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.buildUsersList(this.state.allUsers)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <BlondedcmsUsers navigate={navigate} />
}

export default WithNavigate;