import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class Login extends React.Component {

    state = {
        email: '',
        password: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    });
                    this.props.navigate('/login');
                } else {
                    this.props.navigate('/account');
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            });
            this.props.navigate('/login');
        });
    }

    loginUser(e) {
		e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        const loginerror = document.querySelector('.login-error');
        const verifiederror = document.querySelector('.verified-error');

        loginerror.classList.add('hidden');
        verifiederror.classList.add('hidden');

        axios.post(`${baseUrl}/api/login/`, {
            email: this.state.email,
            password: this.state.password
        },
        { 
            withCredentials: true 
        })
        .then((response) => {
            this.props.navigate('/account');
        })
        .catch((error) => {
            if (error.response.status === 409) {
                loginerror.classList.remove('hidden');
            }

            if (error.response.status === 401) {
                verifiederror.classList.remove('hidden');
            }
        });
	}

    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Inloggen</h1>
                            <div className="intro">
                                <p>Log hieronder met uw persoonlijke gegevens in.</p>            
                            </div>
                        </div>
                        <div className="form-wrapper">

                            <div className="alert alert-error login-error hidden" role="alert">Email of wachtwoord is onjuist.</div>
                            <div className="alert alert-warning verified-error hidden" role="alert">Account is nog niet geverifieerd, bekijk uw inbox voor meer informatie.</div>

                            <form onSubmit={this.loginUser.bind(this)}>
                                <div className="form-group">
                                    <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="email" placeholder="Email" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <input value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} type="password" placeholder="Password" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Inloggen" />
                                </div>
                                <div className="form-actions">
                                    <p className="message">
                                        <a className="link link-important link-arrow" href="/register">Of maak direct een gratis account aan</a>
                                    </p>
                                    <a className="link" href="/wachtwoordvergeten">Wachtwoord vergeten</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Login navigate={navigate} />
}

export default WithNavigate;