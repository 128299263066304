import { useEffect } from 'react';

import { Header, Hero, Usp, Faq, Footer} from './components/common';

import freeAccount from "./images/svg/free-account.svg";
import consult from "./images/svg/consult.svg";
import register from "./images/svg/register.svg";
import play from "./images/videos/play.png";
import videoRegister from "./images/videos/waarom-vonnis-registreren.svg";
import videoConsult from "./images/videos/waarom-vonnisbank-raadplegen.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import './styles/App.scss';
import './styles/pages/section.scss';

function App() {

  // This function will be triggered when the "container" is clicked
  const handleClick = (event) => {
    var id = event.currentTarget.id;
    var nId = id.charAt(id.length - 1);
    document.getElementById("thumb" + nId).style.zIndex = 0;
    document.getElementById("plBtn" + nId).style.zIndex = 0;
    document.getElementById("video" + nId).contentWindow.postMessage(JSON.stringify({method:"play"}), "*" );
  };

  useEffect(() => {
    var scrollElement = document.querySelector('.how-it-works');
    var scrollBtn = document.querySelector('.scrollBtn');

    if (scrollBtn) {
      scrollBtn.addEventListener('click', function() {
        scrollElement.scrollIntoView({
          behavior: 'smooth'
        });
      });
    }
  }, []);

  const homeKvkField = () => {
    window.location.href = "/login";
  };

  return (
      <div className="Main">
           <Header />
           <Hero />
           <Usp />
           <div className="page-section why-us">
             <div className="container mx-auto">
              <h2 className="section-title">Waarom Vonnisbank</h2>
              <div className="section-intro">
                <p>Vonnisbank registreert uitgesproken vonnissen met betrekking tot betalingen tussen bedrijven. Met als doel ondernemers en bedrijven te waarschuwen en kans op betaling te vergroten.<br /> Laat ook uw oude vonnissen registreren.</p>
              </div>
              <div className="section-content">
               <div className="flex flex-row flex-wrap">
                <div className="basis-full md:basis-full lg:basis-1/3 overflow-hidden">
                  <div className="block-wrapper h-full">
                    <div className="why-us-block block-1 h-full">
                      <div className="header">
                        <img src={freeAccount} alt="Gratis account aanmaken" />
                      </div>
                      <div className="content">
                        <h3>Gratis account aanmaken</h3>
                        <p>Een eerste vonnis plaatsen en raadplegen van een KvK-nummer is gratis. Daarna kunt u een jaarabonnement nemen voor €97,00 per jaar.</p>
                        <a className="link-arrow" href="/register">Account aanmaken</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basis-full md:basis-1/2 lg:basis-1/3 overflow-hidden">
                  <div className="block-wrapper h-full">
                    <div className="why-us-block block-2 h-full">
                      <div className="header">
                        <img src={consult} alt="Gratis account aanmaken" />
                      </div>
                      <div className="content">
                        <h3>Raadplegen</h3>
                        <p>Ontdek of er een vonnis geregistreerd is van uw bestaande of nieuwe klanten.</p>
                        <a className="link-arrow" href="/vonnis/zoeken">Zoeken naar vonnissen</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basis-full md:basis-1/2 lg:basis-1/3 overflow-hidden">
                  <div className="block-wrapper h-full">
                    <div className="why-us-block block-3 h-full">
                      <div className="header">
                        <img src={register} alt="Gratis account aanmaken" />
                      </div>
                      <div className="content">
                        <h3>Registreren</h3>
                        <p>Vergroot de kans tot betaling en waarschuw andere bedrijven en ondernemers.</p>
                        <a className="link-arrow" href="/vonnis/plaatsen">Registreer een vonnis</a>
                      </div>
                    </div>
                  </div>
                </div>
               </div>
              </div>
              <div className="register-checker">
                <h3>Check gratis of uw (potentiële) klant staat geregistreerd:</h3>
                <form method="post">
                    <div className="input-group">
                        <input type="text" className="form-control" name="kvk" id="kvk" placeholder="Zoek op KvK-nummer" />
                        <span className="input-group-btn" onClick={homeKvkField}>
                            <FontAwesomeIcon icon={faSearch} />
                        </span>
                    </div>
                </form>
              </div>
             </div>
           </div>
           <div className="page-section how-it-works">
             <div className="container mx-auto">
              <h2 className="section-title">Ontdek hoe het werkt in 40 seconden.</h2>
              <div className="section-content">
                <div className="flex flex-row flex-wrap">
                  <div className="md:basis-1/2 basis-full video">
                      <div className="video-wrapper">
                        <iframe id="video1" title="video-1" src="https://player.vimeo.com/video/189024813?h=2068e4e68e&title=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                        <img className="thumb" id="thumb1" alt="Waarom vonnisbank raadplegen" src={videoConsult} onClick={handleClick}></img>
                        <img className="plBtn" id="plBtn1" alt="Play button waarom vonnisbank raadplegen" src={play} onClick={handleClick}></img>
                      </div>
                      <h3>Waarom vonnisbank raadplegen?</h3>
                      <a className="button btn-arrow" href="/register">Gratis account aanmaken</a>
                  </div>
                  <div className="md:basis-1/2 basis-full video">
                    <div className="video-wrapper">
                      <iframe id="video2" title="video-2" src="https://player.vimeo.com/video/189008711?h=6e69d0de4b&title=0&byline=0&portrait=0" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <img className="thumb" id="thumb2" alt="Waarom vonnis registeren" src={videoRegister} onClick={handleClick}></img>
                        <img className="plBtn" id="plBtn2" alt="Play button Waarom vonnis registeren" src={play} onClick={handleClick}></img>
                    </div>
                    <h3>Waarom een vonnis registeren?</h3>
                    <a className="button btn-arrow" href="/vonnis/plaatsen">Vonnis registeren</a>
                  </div>
                </div>
              </div>
             </div>
           </div>
           <Faq />
           <div className="page-section register bg-pattern front">
            <div className="container mx-auto front">
                <h2 className="section-title">Meld je gratis aan bij Vonnisbank.nl</h2>
                <div className="section-intro">
                    <p>Vul je KvK-nummer en e-mail adres in om jouw gratis Vonnisbank-account aan te maken. Je ontvangt direct een e-mail met jouw logingegevens.</p>
                </div>
                <div className="section-content">
                    <form method="post" autoComplete="off" action="/register/short">
                      <div className="flex">
                        <div className="basis-9/12">
                          <div className="flex">
                            <div className="basis-1/2">
                              <div className="form-group">
                                <input type="text" name="kvk_number" className="form-control" placeholder="KvK-nummer" />
                              </div>
                            </div>
                            <div className="basis-1/2">
                              <div className="form-group">
                                <input type="text" name="email" className="form-control" placeholder="E-mailadres" />
                              </div>
                            </div>                   
                          </div>
                        </div>
                        <div className="basis-3/12">
                          <div className="flex">
                            <div className="basis-full">
                              <div className="form-group">
                                <button className="button button-primary btn-arrow">Gratis aanmelden</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                </div>
                <div className="section-footer">
                    <p>Heeft u hulp nodig? Neem dan <a alt="contact" href="/contact">contact</a> met ons op.</p>
                </div>
            </div>
        </div>
        <Footer />
      </div>
  );
}

export default App;
