import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class ForgotPassword extends React.Component {

    state = {
        email: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    });
                } else {
                    this.props.navigate('/account');
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            });
        });
    }

    PasswordRequest(e) {
		e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        const loginerror = document.querySelector('.login-error');

        loginerror.classList.add('hidden');

        axios.post(`${baseUrl}/api/forgotpassword/`, {
            email: this.state.email
        },
        { 
            withCredentials: true 
        })
        .then((response) => {
            this.props.navigate('/wachtwoordvergeten/mail');
        })
        .catch((error) => {
            if (error.response.status === 409) {
                loginerror.classList.remove('hidden');
            }
        });
	}

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Wachtwoord vergeten</h1>
                            <div className="intro">
                                <p>Vul hieronder uw e-mailadres in.<br /> Wij sturen u dan per e-mail een link waarmee u het wachtwoord kunt wijzigen.</p>            
                            </div>
                        </div>
                        <div className="form-wrapper">

                            <div className="alert alert-error login-error hidden" role="alert">Email is niet bij ons bekend.</div>

                            <form onSubmit={this.PasswordRequest.bind(this)}>
                                <div className="form-group">
                                    <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} type="email" placeholder="Email" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Verder" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <ForgotPassword navigate={navigate} />
}

export default WithNavigate;