import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/backend/account.scss'

class Colleagues extends React.Component {

    state = {
        //GET ACCOUNT INFO
        user: {},
        colleagues: [],
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        this.getColleaguesInfo();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getColleaguesInfo() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/company/${this.state.user.id}/colleagues`, {
            withCredentials: true,
        })
        .then(response => {
            this.setState({ colleagues: response.data.colleagues });
        })
        .catch(error => {
            console.log(error);
        });
    }

    inactiveColleague(e) {
        const baseUrl = process.env.REACT_APP_API_BASEURL;
        
        var emailParent = e.target.closest('.colleague');
        var email = emailParent.querySelector('#email').innerHTML;
        
        axios.get(`${baseUrl}/api/user/inactive/${this.state.user.id}/${email}`, {
            withCredentials: true,
        })
        .then(response => {
            window.location.reload(false);
        })
        .catch(error => {
            console.log(error);
        });
    }

    buildColleaguesList(colleageuData) {
        if (!colleageuData[0]) {
            return;
        }

        if (colleageuData[0].length > 0 && this.state.user.email === this.state.colleagues[1]) {
            return colleageuData[0].map((colleageuData, index) => (
                <tr key={index} className={colleageuData.active ? "colleague" : "colleague sr-only"}>
                  <td id="email">{colleageuData.email}</td>
                  <td>{colleageuData.name}</td>
                  <td>{colleageuData.phone}</td>
                  <td>{colleageuData.createdAt.substr(0, 10)}</td>
                  <td><button onClick={this.inactiveColleague.bind(this)}>Verwijderen</button></td>
                </tr>
            ));
        } else if (colleageuData[0].length > 0) {
            return colleageuData[0].map((colleageuData, index) => (
                <tr key={index} className={colleageuData.active ? "colleague" : "colleague sr-only"}>
                  <td>{colleageuData.email}</td>
                  <td>{colleageuData.name}</td>
                  <td>{colleageuData.phone}</td>
                  <td>{colleageuData.createdAt.substr(0, 10)}</td>
                  <td></td>
                </tr>
            ));
        }
    }
    
    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Mijn collega's</h1>
                            <div className="intro">
                                <p>Uw collega's binnen {}</p>
                            </div>
                        </div>
                        <div className='md:flex flex-row flex-wrap'>
                            <div className='basis-full'>
                                <div className="table-responsive">
                                    <table className="data-table colleague-table">
                                        <thead>
                                            <tr>
                                                <th>E-mailadres</th>
                                                <th>Naam</th>
                                                <th>Telefoonnummer</th>
                                                <th>Aangemaakt op</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.buildColleaguesList(this.state.colleagues)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <Colleagues navigate={navigate} />
}

export default WithNavigate;