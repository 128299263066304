import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

function App() {
    return (
        <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Hoe het werkt</h1>
                        </div>
                        <p>
                            <strong>HOE HET WERKT</strong>
                        </p>
                        <p>
                            Bij Vonnisbank kunt u checken of uw nieuwe klant uw vertrouwen waard is én zijn financiële verplichtingen nakomt.
                            Treft u een vonnis aan van het bedrijf waarmee u zaken wilt gaan doen? Dan weet u dat u voorzichtig moet zijn,
                            maak dan goede afspraken.
                        </p>
                        <p>
                            Als schuldeiser kunt u dit uitgesproken vonnis plaatsen in onze Vonnisbank. Uw niet-betalende klant krijgt bericht
                            over de plaatsing hiervan. Dit vergroot de kans dat hij alsnog contact met u opneemt en overgaat tot betaling. 
                            Gebeurt dit niet, dan voorkomt u in ieder geval dat dit bedrijf nog meer schade aanricht bij uw collega-ondernemers.
                        </p>

                        <p>
                            <strong>Vonnis zoeken</strong>
                        </p>
                        <p>
                            Bedrijven kunnen gratis een vonnis zoeken op Vonnisbank, het enige wat u moet doen is uzelf aanmelden met uw KvK-nummer.
                            Zo checkt u met één klik of uw nieuwe klant daadwerkelijk uw vertrouwen waard is.
                        </p>

                        <p>
                            <strong>Vonnis plaatsen</strong>
                        </p>
                        <p>
                            Bedrijven kunnen ook een vonnis plaatsen. U kunt daarvoor op basis van uw Kamer van Koophandel nummer een account aanmaken
                            om een vonnis te plaatsen. Na het invullen van een aantal controlevragen gaat Vonnisbank over tot het plaatsen van een vonnis.
                            De schuldenaar krijgt bericht over de plaatsing van dit vonnis. Komt deze in beweging en neemt hij contact met u op om alsnog
                            de factuur te betalen? Dan kunt u als schuldeiser de vonnis registratie de status betaald geven en sluiten. Na controle door 
                            Vonnisbank kan de schuldenaar tot slot het vonnis laten verwijderen.
                        </p>

                        <p>
                            <strong>Wat kost het gebruik van Vonnisbank?</strong>
                        </p>
                        <p>
                            Het gebruik van Vonnisbank.nl kost € 97,00 excl. btw. per jaar.
                        </p>
                        <p>
                            Her-incasso van oude vonnissen, kost € 200,00 excl. btw. per vonnis.
                        </p>
                        <p>Het bedrijfsrapport kost € 12,50 excl. btw.</p>
                        <p>
                            Wilt u meerdere vonnissen plaatsen? Wij bieden ook business accounts aan voor eenvoudigere registratie. Neem dan contact op; info@vonnisbank.nl
                        </p>

                        <p>
                            <strong>Vonnisbank kan op verzoek uw vonnis opnemen in het schuldenaar volgsysteem.</strong>
                        </p>
                        <p>
                            Vonnisbank werkt samen met geselecteerde deurwaarders en wij bieden u als plaatser van een vonnis, een schuldenaar volgsysteem aan. Dit houdt in
                            dat wij de schuldenaar actief zullen volgen en bij positieve ontwikkelingen het vonnis kunnen innen. Deze dienst biedt Vonnisbank uitsluitend
                            op aanvraag aan en wordt in overleg geactiveerd. Neem hiervoor contact met ons op.
                        </p>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;