import { Header, Footer } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

function App() {
    return (
        <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <h1>Disclaimer</h1>                 
                        </div>
                        <p>
                            Het samenstellen van de internetpagina's van Vonnisbank.nl, gebeurt met grote zorg. Desondanks kan het
                            voorkomen dat informatie onvolledig of onjuist op de pagina's is terechtgekomen. Er kunnen geen rechten
                            worden ontleend aan de aangeboden informatie. Vonnisbank.nl BV of haar medewerkers kunnen evenmin aansprakelijk
                            worden gesteld voor eventuele gevolgen of opgelopen schade vanwege de aangeboden informatie.
                        </p>
                        <p>
                            Op de internetpagina's van Vonnisbank.nl staan enkele links die verwijzen naar internetpagina's van derden
                            die niet onder het domein van Vonnisbank.nl vallen. Op de inhoud van deze pagina's hebben de medewerkers
                            van Vonnisbank.nl geen enkele invloed. Vonnisbank.nl of haar medewerkers aanvaarden dan ook geen enkele
                            aansprakelijkheid voor de aldaar aangeboden informatie.
                        </p>
                    </div>
                </div>
             <Footer />
        </div>
    );
}

export default App;