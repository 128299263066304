import React from "react";
import hammers from '../../../images/svg/hammers.svg';

class Faq extends React.Component {

    componentDidMount() {
        var faqItems = document.querySelectorAll('.faq-item');
        for (var i = 1; i <= faqItems.length; i++) {
            document.querySelector('#a' + i).style.display = "none";
        }
    }

    answerQuestion(event) {
        var faqItem = event.target.parentNode;
        var question = event.target.id;
        var questionId = question.charAt(question.length - 1);

        if (faqItem.classList.contains('expanded')) {
            faqItem.classList.remove('expanded');
            document.querySelector('#a' + questionId).style.display = "none";
        } else {
            faqItem.classList.add('expanded');
            document.querySelector('#a' + questionId).style.display = "block";
        }
    }

    render() {

        if(window.location.pathname === "/") {
            return (
                <div className="page-section Faq">
                    <div className="container mx-auto">
                        <h2 className="section-title">
                            <img src={hammers} alt="hammers" />
                            Veelgestelde vragen
                        </h2>
                        <div className="section-content">
                            <div className="faq-item expanded">
                                <div className="question" id="q1" onClick={this.answerQuestion}>Hoe werkt het nu precies?</div>
                                <div className="answer" id="a1">
                                    <p>kijk ook op <a href="https://www.vonnisbank-uitleg.nl/" target="_blank" rel="noreferrer">www.vonnisbank-uitleg.nl/</a> voor heldere en duidelijke uitleg.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q2" onClick={this.answerQuestion}>Vonnisbank incasseert WÉL uw oude vonnis</div>
                                <div className="answer" id="a2">
                                    <p>
                                        Vonnisbank zorgt ervoor dat uw oude en inmiddels afgeschreven vonnis wel betaald gaat worden.
                                        Door samenwerking met geselecteerde deurwaarders pakken wij oude vonnissen weer op en gaan deze incasseren voor u!
                                    </p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q3" onClick={this.answerQuestion}>Wat is een vonnis?</div>
                                <div className="answer" id="a3">
                                    <p>Dit is een uitspraak van een rechter waaruit blijkt dat de vordering aan een schuldenaar is toegewezen. De rechter vindt dat de schuldeiser volgens afspraak diensten of producten heeft geleverd en de vooraf overeengekomen betaling is gerechtvaardigd. De klant moet de opdrachtgever dus netjes betalen.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q4" onClick={this.answerQuestion}>Vonnisbank werkt samen met deurwaarders, wat is uw belang daarbij?</div>
                                <div className="answer" id="a4">
                                    <p>
                                    Doordat Vonnisbank samenwerkt met geselecteerde deurwaarders bieden wij u de mogelijkheid om uw oude vonnis alsnog geïncasseerd te krijgen. Vonnisbank blaast weer "leven" in uw vonnis(sen). 
                                    Dit houdt in dat wij de schuldenaar kunnen bezoeken en in het volgsysteem opnemen zodat bij eventuele positieve ontwikkelingen, uw vordering alsnog betaald gaat worden.
                                    Deze dienst is op aanvraag en wordt uitsluitend in overleg geactiveerd.
                                    </p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q5" onClick={this.answerQuestion}>Is het gerechtvaardigd om een handel vonnis te registeren van mijn schuldenaar op Vonnisbank.nl?</div>
                                <div className="answer" id="a5">
                                    <p>Ja. De rechter vindt dat de schuldeiser volgens afspraak diensten of producten heeft geleverd en de vooraf overeengekomen betaling is gerechtvaardigd. De klant moet de opdrachtgever dus netjes betalen. Als de schuldenaar dit niet doet, ondanks een gerechtelijke uitspraak, is het gerechtvaardigd om het bedrijf te registeren bij Vonnisbank.nl. Daarbij is het vonnis openbaar.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q6" onClick={this.answerQuestion}>Waarin verschilt Vonnisbank met het controleren van de kredietwaardigheid van een nieuwe klant?</div>
                                <div className="answer" id="a6">
                                    <p>Het controleren van de kredietwaardigheid van een bedrijf geschiedt altijd op basis van cijfers uit het verleden. Vonnisbank geeft een actueel en concreet inzicht in het betalingsgedrag van een nieuwe klant. Daardoor kunt u betere afspraken maken deze klant of eventueel afzien van een samenwerking.</p>
                                </div>
                            </div>
                        </div>
                        <div className="section-footer">
                            <p>Staat uw vraag hier niet tussen? <br />
                                Bekijk de rest van onze <a href="/faq" alt="veelgestelde vragen">veelgestelde vragen</a> en vind uw antwoord.</p>
                            <a className="button button-primary btn-arrow" href="/faq" alt="veelgestelde vragen">Naar onze FAQ</a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-section Faq bg-pattern front">
                    <div className="container mx-auto">
                        <div className="section-content">
                            <div className="faq-item expanded">
                                <div className="question" id="q1" onClick={this.answerQuestion}>Hoe werkt het nu precies?</div>
                                <div className="answer" id="a1">
                                    <p>kijk ook op <a href="https://www.vonnisbank-uitleg.nl/" target="_blank" rel="noreferrer">www.vonnisbank-uitleg.nl/</a> voor heldere en duidelijke uitleg.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q2" onClick={this.answerQuestion}>Vonnisbank incasseert WÉL uw oude vonnis</div>
                                <div className="answer" id="a2">
                                    <p>
                                        Vonnisbank zorgt ervoor dat uw oude en inmiddels afgeschreven vonnis wel betaald gaat worden.
                                        Door samenwerking met geselecteerde deurwaarders pakken wij oude vonnissen weer op en gaan deze incasseren voor u!
                                    </p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q3" onClick={this.answerQuestion}>Wat is een vonnis?</div>
                                <div className="answer" id="a3">
                                    <p>Dit is een uitspraak van een rechter waaruit blijkt dat de vordering aan een schuldenaar is toegewezen. De rechter vindt dat de schuldeiser volgens afspraak diensten of producten heeft geleverd en de vooraf overeengekomen betaling is gerechtvaardigd. De klant moet de opdrachtgever dus netjes betalen.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q4" onClick={this.answerQuestion}>Vonnisbank werkt samen met deurwaarders, wat is uw belang daarbij?</div>
                                <div className="answer" id="a4">
                                    <p>
                                    Doordat Vonnisbank samenwerkt met geselecteerde deurwaarders bieden wij u de mogelijkheid om uw oude vonnis alsnog geïncasseerd te krijgen. Vonnisbank blaast weer "leven" in uw vonnis(sen). 
                                    Dit houdt in dat wij de schuldenaar kunnen bezoeken en in het volgsysteem opnemen zodat bij eventuele positieve ontwikkelingen, uw vordering alsnog betaald gaat worden.
                                    Deze dienst is op aanvraag en wordt uitsluitend in overleg geactiveerd.
                                    </p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q5" onClick={this.answerQuestion}>Is het gerechtvaardigd om een handel vonnis te registeren van mijn schuldenaar op Vonnisbank.nl?</div>
                                <div className="answer" id="a5">
                                    <p>Ja. De rechter vindt dat de schuldeiser volgens afspraak diensten of producten heeft geleverd en de vooraf overeengekomen betaling is gerechtvaardigd. De klant moet de opdrachtgever dus netjes betalen. Als de schuldenaar dit niet doet, ondanks een gerechtelijke uitspraak, is het gerechtvaardigd om het bedrijf te registeren bij Vonnisbank.nl. Daarbij is het vonnis openbaar.</p>
                                </div>
                            </div>
                            <div className="faq-item">
                                <div className="question" id="q6" onClick={this.answerQuestion}>Waarin verschilt Vonnisbank met het controleren van de kredietwaardigheid van een nieuwe klant?</div>
                                <div className="answer" id="a6">
                                    <p>Het controleren van de kredietwaardigheid van een bedrijf geschiedt altijd op basis van cijfers uit het verleden. Vonnisbank geeft een actueel en concreet inzicht in het betalingsgedrag van een nieuwe klant. Daardoor kunt u betere afspraken maken deze klant of eventueel afzien van een samenwerking.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default Faq;