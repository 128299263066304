import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

class Rapport extends React.Component {

    state = {
        user: {},
        cocNumber: '',
        vonnisPdf: {},
        betekening: {}
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    bedrijfsrapportCreate(e) {
		e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.post(`${baseUrl}/api/bedrijfsrapport/create/${this.state.user.id}`, {
            cocNumber: this.state.cocNumber
        },
        { 
            withCredentials: true 
        })
        .then((response) => {
            this.props.navigate('/bedrijfsrapport/opgevraagd');
        })
        .catch((error) => {
            console.log(error);
        });
    }

    kvkLookup(e) {

        if (e.type === 'keydown' && e.code !== 'Enter') {
            return;
        }

        if (this.state.cocNumber.length !== 8) {
            return;
        }

        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/kvklookup/${this.state.cocNumber}`)
        .then((response) => {
            var kvkInfoField = document.querySelector('#kvkLookupResult');

            kvkInfoField.innerHTML = "Bezig met zoeken..."

            setTimeout(() => {
                var companyInfo = JSON.parse(response.data.kvk);

                if (companyInfo.resultaten) {
                    kvkInfoField.innerHTML = `
                        <p><b>${companyInfo.resultaten[0].handelsnaam}</b></p>
                        <p>${companyInfo.resultaten[0].plaats}</p>
                        <p>${companyInfo.resultaten[0].straatnaam}</p>
                    `
                } else {
                    kvkInfoField.innerHTML = `
                        <p><b>Er zijn geen gegevens gevonden</b></p>
                        <p>Indien u er zeker van bent dat u de juiste gegevens heeft kunt u deze handmatig invullen</p>
                    `
                }
            }, 1000);

        })
        .catch((error) => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="main-container faq-page">
                <div className="page-intro">
                    <div className="container mx-auto">
                        <h1>Bedrijfsrapport opvragen</h1>
                        <div className="intro">
                            <p>
                                Met dit formulier kunt u een bedrijfsrapport aanvragen.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="bg-pattern front">
                    <div className="main-container container mx-auto">
                        <div className="form-wrapper">

                            <form onSubmit={this.bedrijfsrapportCreate.bind(this)}>
                                <div className="form-group kvk">
                                    <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} onKeyDown={this.kvkLookup.bind(this)} type="text" placeholder="KvK-nummer" className="form-control" id="kvk" required />
                                    <div className="input-group-btn">
                                        <button className="btn btn-small btn-default" type="button" onClick={this.kvkLookup.bind(this)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="alert alert-info" id="kvkLookupResult">Vul een KvK-nummer in om te zoeken</div>
                                </div>
                                
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Bedrijfsrapport opvragen"/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
    
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Rapport navigate={navigate} />
}

export default WithNavigate;