import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class AccountEdit extends React.Component {

    state = {
        address: '',
        postalCode: '',
        city: '',
        name: '',
        number: '',
        email: '',
        user: {},
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.data.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/register');
                } else {
                    this.setState({user: user}, () => {
                        this.fillFields();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    fillFields() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/account/` + this.state.user.id, {
            withCredentials: true,
        })
        .then(response => { 
            const userinfo = response.data.userinfo;

            this.setState({name: userinfo.name});
            this.setState({address: userinfo.address});
            this.setState({postalCode: userinfo.postalCode});
            this.setState({city: userinfo.city});
            this.setState({number: userinfo.phone});
            this.setState({email: userinfo.email});
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    registerUser(e) {
		e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.post(`${baseUrl}/api/account/edit/${this.state.user.id}`, {
            address: this.state.address,
            postalCode: this.state.postalCode,
            city: this.state.city,
            name: this.state.name,
            number: this.state.number,
            email: this.state.email,
        },
        {
            withCredentials: true 
        })
        .then((response) => {
            this.props.navigate('/account');
        })
        .catch((error) => {
            console.log(error);
        });
	}

    render() {
        return (
            <div className="Main">
             <Header />
             <div className="bg-pattern front">
                <div className="container mx-auto main-container">
                    <div className="page-intro">
                        <img src={Logovisual} className="logo-visual" alt="logo visual" />
                        <h1>Accountgegevens wijzigen</h1>
                    </div>
                    <div className="form-wrapper">

                        <form onSubmit={this.registerUser.bind(this)}>
                            <div className="form-group">
                                <input value={this.state.address} onChange={(e) => this.setState({address: e.target.value})} type="text" placeholder="Adres" className="form-control address" required />
                            </div>
                            <div className='grid sm:grid-cols-12 sm:gap-2'>
                                <div className="form-group sm:col-span-5 col-span-12">
                                    <input value={this.state.postalCode} onChange={(e) => this.setState({postalCode: e.target.value})} type="text" placeholder="Postcode" className="form-control" required />
                                </div>
                                <div className="form-group sm:col-span-7 col-span-12">
                                    <input value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} type="text" placeholder="Plaats" className="form-control city"  />
                                </div>
                            </div>
                            <div className="form-group">
                                <input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} type="text" placeholder="Naam contactpersoon" className="form-control" required />
                            </div>
                            <div className="form-group">
                            <div className="form-group">
                                <input value={this.state.number} onChange={(e) => this.setState({number: e.target.value})} type="text" placeholder="Telefoonnummer" className="form-control" required />
                            </div>
                            </div>
                            <div className="form-group">
                                <input type="submit" className="button button-primary button-block button-big button-register" value="Accountgegevens wijzigen" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <AccountEdit navigate={navigate} />
}

export default WithNavigate;