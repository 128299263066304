import React from "react";
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/backend/vonnisPages.scss'

class HerincassoCreated extends React.Component {

    state = {
        herincasso: [],
        cocNumber: '',
        token: {}
    };

    componentDidMount() {
        setTimeout(() => {
            this.auth();
            this.redirect();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        this.redirect();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    redirect() {
        setTimeout(() => {
            this.props.navigate('/account');
        }, 5000);
    }

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="bg-pattern front h-[45vh]">
                    <div className="container mx-auto h-full flex items-center justify-center">
                        <div className="alert alert-success text-center" role="alert">Herincasso succesvol geplaatst, u ontvangt een mail ter bevestiging.<br />(u wordt automatisch doorverwezen)</div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <HerincassoCreated navigate={navigate} />
}

export default WithNavigate;