import React from "react";
import jwt_decode  from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCaretDown } from '@fortawesome/free-solid-svg-icons'

import Logovisual from "../../../images/logo-visual.svg";
import Logotextual from "../../../images/logo-textual.svg";

class Header extends React.Component {

    state = {
        role: '',
        customerOf: ''
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({ role: user.role });
                    this.setState({ customerOf: user.customerOf });
                }
            }
        })
        .catch(error => {
            this.setState({ role: '' });
        });
    }

    toggleMenu() {
        const menu = document.querySelector('.mobile-menu'); 

        if (menu.style.display === 'none') { 
            menu.style.display = 'block';
        } else {
            menu.style.display = 'none';
        }
    }

    logoutUser() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/clearauth/`, {
            withCredentials: true,
        })
        this.props.navigate('/login');
    }

    render() {
        if (this.state.role === process.env.REACT_APP_AUTH_ROLE_USER || this.state.role === process.env.REACT_APP_AUTH_ROLE_BUSINESS || this.state.role === process.env.REACT_APP_AUTH_ROLE_SUBSCRIBER) {
            return (
                <header className="Header header-alt role-user">
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="lg:basis-2/12 md:basis-3/12 basis-7/12">
                                <div className="logo">
                                    <a href="/">
                                        <img src={Logovisual} className="logo-visual" alt="logo-visual" />
                                        <img src={Logotextual} className="logo-textual" alt="logo-textual" />
                                    </a>
                                </div>
                            </div>
                            <div className="lg:basis-8/12 md:basis-6/12 hidden md:block text-center">
                                <ul className="user-actions-menu">
                                    <li className="hidden lg:inline"><a className="header-link" href="/vonnis/zoeken">Vonnissen</a></li>
                                    <li><a className="header-link" href="/vonnis/plaatsen">Nieuw vonnis plaatsen</a></li>
                                    <li className="hidden lg:inline"><a className="header-link" href="/vonnis/mijn">Mijn vonnissen</a></li>
                                </ul>
                            </div>
                            <div className="lg:basis-2/12 md:basis-3/12 basis-5/12 flex justify-end">
                                <div className="user-menu">
                                    <div className="user-menu-trigger" onClick={() => this.toggleMenu()}>
                                        <div className="user-image">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                        <div className="caret-down">
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </div>
                                    </div>
                                    <ul className="menu mobile-menu" style={{display: 'none'}}>
                                        <li className="inline md:hidden"><a className="header-link" href="/vonnis/plaatsen">Nieuw vonnis plaatsen</a></li>
                                        <li className="inline lg:hidden"><a className="header-link" href="/vonnis/zoeken">Vonnissen</a></li>
                                        <li className="inline lg:hidden"><a className="header-link" href="/vonnis/mijn">Mijn vonnissen</a></li>                        
                                        <li><a className="header-link" href="/account">Mijn account</a></li>
                                        <li><a className="header-link" href="/colleagues">Mijn collega's</a></li>
                                        {/* {this.state.customerOf === 'flynth' ? <li><a className="header-link" href="/flynth">Flynth</a></li> : null} */}
                                        <li><button className="header-link" onClick={() => this.logoutUser()}>Uitloggen</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            );
        }  else if (this.state.role === process.env.REACT_APP_AUTH_ROLE_ADMIN) {
            return (
                <header className="Header header-alt role-user">
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="lg:basis-2/12 md:basis-3/12 basis-7/12">
                                <div className="logo">
                                    <a href="/">
                                        <img src={Logovisual} className="logo-visual" alt="logo-visual" />
                                        <img src={Logotextual} className="logo-textual" alt="logo-textual" />
                                    </a>
                                </div>
                            </div>
                            <div className="lg:basis-8/12 md:basis-6/12 hidden md:block text-center">
                                <ul className="user-actions-menu">
                                    <li className="hidden lg:inline"><a className="header-link" href="/vonnis/zoeken">Vonnissen</a></li>
                                    <li><a className="header-link" href="/vonnis/plaatsen">Nieuw vonnis plaatsen</a></li>
                                    <li className="hidden lg:inline"><a className="header-link" href="/vonnis/mijn">Mijn vonnissen</a></li>
                                </ul>
                            </div>
                            <div className="lg:basis-2/12 md:basis-3/12 basis-5/12 flex justify-end">
                                <div className="user-menu">
                                    <div className="user-menu-trigger" onClick={() => this.toggleMenu()}>
                                        <div className="user-image">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                        <div className="caret-down">
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </div>
                                    </div>
                                    <ul className="menu mobile-menu" style={{display: 'none'}}>
                                        <li className="inline md:hidden"><a className="header-link" href="/vonnis/plaatsen">Nieuw vonnis plaatsen</a></li>
                                        <li className="inline lg:hidden"><a className="header-link" href="/vonnis/zoeken">Vonnissen</a></li>
                                        <li className="inline lg:hidden"><a className="header-link" href="/vonnis/mijn">Mijn vonnissen</a></li>                        
                                        <li><a className="header-link" href="/account">Mijn account</a></li>
                                        <li><a className="header-link" href="/colleagues">Mijn collega's</a></li>
                                        {/* {this.state.customerOf === 'flynth' ? <li><a className="header-link" href="/flynth">Flynth</a></li> : null} */}
                                        <li><a className="header-link" href="/blondedcms/admin">Admin</a></li>
                                        <li><button className="header-link" onClick={() => this.logoutUser()}>Uitloggen</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            );
        }

        if (window.location.pathname === '/') {
            return (
                <header className="Header">
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="basis-1/2">
                                <a href="/faq" className="header-link">Veelgestelde vragen</a>
                            </div>
                            <div className="basis-1/2 flex justify-end">
                                <a href="/login" className="button btn-small">Inloggen</a>
                            </div>
                        </div>
                    </div>
                </header>
            );
        } else {
            return (
                <header className="Header header-alt">
                    <div className="container mx-auto">
                        <div className="flex">
                            <div className="basis-1/3">
                                <div className="logo">
                                    <a href="/">
                                        <img src={Logovisual} className="logo-visual" alt="logo-visual" />
                                        <img src={Logotextual} className="logo-textual" alt="logo-textual" />
                                    </a>
                                </div>
                            </div>
                            <div className="basis-1/3 text-center">
                                <a href="/faq" className="header-link">Veelgestelde vragen</a>
                            </div>
                            <div className="basis-1/3 flex justify-end">
                                <a href="/login" className="button btn-small">Inloggen</a>
                            </div>
                        </div>
                    </div>
                </header>
            );
        }
    }
        
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Header navigate={navigate} />
}

export default WithNavigate;