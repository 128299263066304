import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Dashboard, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/backend/account.scss'

class Account extends React.Component {

    state = {
        //CHANGE PASSWORD
        currentPassword: '',
        newPassword: '',
        newPasswordRepeat: '',

        //GET ACCOUNT INFO
        user: {},
        name: '',
        companyName: 'Toegang aangevraagd',
        cocNumber: 'Toegang aangevraagd',
        address: '',
        postalCode: '',
        city: '',
        number: '',
        email: '',
        customerOf: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user}, () => {
                        this.getAccountInfo();
                    });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getAccountInfo() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/account/` + this.state.user.id, {
            withCredentials: true,
        })
        .then(response => { 
            const userinfo = response.data.userinfo;

            this.setState({name: userinfo.name});

            if (!userinfo.companyName.includes('NONE')) {
                this.setState({companyName: userinfo.companyName});
            }

            if (userinfo.cocNumber.toString().length === 8) {
                this.setState({cocNumber: userinfo.cocNumber});
            }

            this.setState({address: userinfo.address});
            this.setState({postalCode: userinfo.postalCode});
            this.setState({city: userinfo.city});
            this.setState({number: userinfo.phone});
            this.setState({email: userinfo.email});
            this.setState({customerOf: userinfo.customerOf});
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    changePassword(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;
        
        const alert = document.querySelector('.alert-error');
        const wrongpass = document.querySelector('.password-wrong');
        const strengthpass = document.querySelector('.password-strength');
        const samepass = document.querySelector('.password-same');
        const currentpass = document.querySelector('.password-current');
        const passchanged = document.querySelector('.alert-success');

        alert.classList.add('hidden');
        wrongpass.classList.add('hidden');
        strengthpass.classList.add('hidden');
        samepass.classList.add('hidden');
        currentpass.classList.add('hidden');
        passchanged.classList.add('hidden');

        if (this.state.newPassword === this.state.newPasswordRepeat) {

            const strengthpass = document.querySelector('.password-strength');
            const alert = document.querySelector('.alert-error');

            const newPassword = this.state.newPassword;
            const regularExpression = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})"); // eslint-disable-line

            if(!regularExpression.test(newPassword)) {
                alert.classList.remove('hidden');
                strengthpass.classList.remove('hidden');
            } else {
                axios.post(`${baseUrl}/api/passwordchange/` + this.state.user.id, 
                {
                    currentPassword: this.state.currentPassword,
                    newPassword: this.state.newPassword,
                    newPasswordRepeat: this.state.newPasswordRepeat,
                }, {
                    withCredentials: true,
                })
                .then(response => {
                    passchanged.classList.remove('hidden');

                    this.setState({ currentPassword: '' });
                    this.setState({ newPassword: '' });
                    this.setState({ newPasswordRepeat: '' });
                })
                .catch(error => {
                    if (error.response.status === 409) {
                        //Wachtwoord hetzelfde als huidig
                        alert.classList.remove('hidden');
                        currentpass.classList.remove('hidden');
                    }

                    if (error.response.status === 401) {
                        //Huidig wachtwoord incorrect
                        alert.classList.remove('hidden');
                        wrongpass.classList.remove('hidden');
                    }
                });
            }

        } else {
            //Wachtwoord herhalen niet hetzelfde
            alert.classList.remove('hidden');
            samepass.classList.remove('hidden');
        }
    }
    
    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <Dashboard />
                    <div className="container mx-auto main-container">   
                        <div className="page-intro">
                            <h1>Mijn account</h1>
                            <div className="intro">
                                <p>Uw gegevens</p>
                            </div>
                        </div>
                        <div className='flex flex-row flex-wrap'>
                            <div className='md:basis-8/12 basis-full px-4'>
                                <div className="table-responsive">
                                    <table className="data-table">
                                        <tbody>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Naam</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.name}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Bedrijfsnaam</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.companyName}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>KvK-nummer</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.cocNumber}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Adres</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.address}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Postcode</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.postalCode}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Plaats</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.city}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>Telefoonnummer</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.number}</td>
                                            </tr>
                                            <tr>
                                                <th className='md:text-base text-xs md:px-3 px-2'>E-mailadres</th>
                                                <td className='md:text-base text-xs md:px-3 px-2'>{this.state.email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='md:basis-4/12 basis-full px-4 md:py-0 pt-6'>
                                <div className='reset-password'>
                                    <h2>Wachtwoord instellen</h2>
                                    <p>Vul hieronder 2x uw nieuwe wachtwoord in.</p>

                                    <div className="alert alert-error hidden" role="alert">
                                        <ul>
                                            <li className="password-wrong hidden">Huidig wachtwoord is onjuist.</li>
                                            <li className="password-strength hidden">Uw wachtwoord moet voldoen aan de volgende voorwaarden: minimaal 1 kleine letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken en minimaal 7 tekens lang.</li>
                                            <li className="password-same hidden">Wachtwoorden komen niet overeen.</li>
                                            <li className="password-current hidden">Nieuwe wachtwoord moet anders zijn.</li>
                                        </ul>
                                    </div>

                                    <div className="alert alert-success hidden" role="alert">Wachtwoord gewijzigd.</div>


                                    <div className="form-wrapper">
                                        <form onSubmit={this.changePassword.bind(this)} id="change-pass-form">
                                            <div className="form-group">
                                                <input value={this.state.currentPassword} onChange={(e) => this.setState({currentPassword: e.target.value})} type="password" placeholder="Huidig wachtwoord" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <input value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})} type="password" placeholder="Nieuw wachtwoord" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <input value={this.state.newPasswordRepeat} onChange={(e) => this.setState({newPasswordRepeat: e.target.value})} type="password" placeholder="Nieuw wachtwoord (herhalen)" className="form-control" required />
                                            </div>
                                            <div className="form-group">
                                                <input type="submit" className="button button-primary button-block button-big" value="Wachtwoord wijzigen" />
                                            </div>
                                        </form>
                                        <div className="form-group">
                                            <a href='/account/edit' className="button button-primary button-block button-big ghost">Accountgegevens wijzigen</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <Account navigate={navigate} />
}

export default WithNavigate;