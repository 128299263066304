import { Header, Footer, Faq } from '../../components/common';
import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';


function App() {
    return (
        <div className="Main">
             <Header />
                <div className="main-container faq-page">
                    <div className="page-intro">
                        <div className="container mx-auto">
                            <h1>Veel gestelde vragen</h1>
                            <div className="intro">
                                <p>Het algemeen overzicht van alle vragen en antwoorden in de Faq database</p>        
                            </div>
                        </div>
                    </div>
                    <Faq />
                </div>
             <Footer />
        </div>
    );
}

export default App;