import React from "react";
import Logovisual from "../../../images/logo-visual.svg";
import Logotextual from "../../../images/logo-textual.svg";
import hammer from "../../../images/svg/hammer.svg";
import hammerblock from "../../../images/svg/hammerblock.svg";

import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import $ from 'jquery';

class Hero extends React.Component {

    state = {
        //GET ACCOUNT INFO
        user: {},
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)

        $(window).on('scroll', function(){

            var scrollTop = $(window).scrollTop();
            var rotation = (scrollTop * .1) + 60;
        
            if(rotation < 60)
                rotation = 60;
            else if(rotation > 90)
                rotation = 90;
        
            $('#hammer-left').css({
                'transform': 'rotate(' + rotation + 'deg) scaleX(-1)'
            });
            $('#hammer-right').css({
                'transform': 'rotate(-' + rotation + 'deg)'
            });
        
        });
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user || user.role !== 'vonnisbank_admin') {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                } else {
                    this.setState({ user: user });
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
        });
    }

    render() {
        return (
            <div className="Hero">
            <div className="front container mx-auto">
                <div className="logo">
                    <img src={Logovisual} className="logo-visual" alt="logo-visual" />
                    <img src={Logotextual} className="logo-textual" alt="logo-textual" />
                </div>
                <div className="text-center">
                    <h1>Vonnissen registreren om betaald te krijgen.</h1>
                    <p>voor incasso-ondernemers en bedrijven</p>
                    {
                    this.state.user.id ?
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <a href="/vonnis/plaatsen" className="button button-success m-0">Vonnis plaatsen</a>
                        <a href="/bedrijfsrapport/opvragen" className="button button-success m-0">Bedrijfsrapport opvragen</a>
                        <a href="/herincasso/plaatsen" className="button button-success m-0">Her-incasso indienen</a>
                        <a href="/incasso/plaatsen" className="button button-success m-0">Incasso indienen</a>
                    </div> : 
                    <>
                        <a href="/register" className="button button-success">Gratis account aanmaken</a>
                        <div>
                            <button className="link-arrow scrollBtn">Of ontdek hoe het werkt</button>
                        </div>
                    </>
                    }
                </div>
            </div>
            <div className="back">
                <img id="hammer-left" className="hammer" src={hammer} alt="hamer" />
                <img id="hammer-right" className="hammer" src={hammer} alt="hamer" />
                <img id="hammer-block-left" className="hammer-block" src={hammerblock} alt="hamer blok" />
                <img id="hammer-block-right" className="hammer-block" src={hammerblock} alt="hamer blok" />
            </div>
        </div>
        )
    }
}

export default Hero;