import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class Contact extends React.Component {

    state = {
        name: '',
        number: '',
        email: '',
        message: '',
        password: '',
    }

    contactForm(e) {
        e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;
        
        if (this.state.password === '') {
            axios.post(`${baseUrl}/api/contactForm/`, {
                name: this.state.name,
                number: this.state.number,
                email: this.state.email,
                message: this.state.message,
                password: this.state.password,
            },
            {
                withCredentials: true 
            })
            .then((response) => {
                this.props.navigate('/contact/verzonden');
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }

    render() {
        return (
            <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Contact opnemen</h1>
                            <div className="intro">
                                <p>Vul het onderstaande formulier in om contact met ons op te nemen</p>
                            </div>
                        </div>

                        <div className="form-wrapper">
                            <h2>Onze gegevens</h2>
                            <p>
                                Vonnisbank.nl BV<br />
                                Mingersbergstraat 29<br />
                                5045EM Tilburg
                            </p>

                            <form className="contact-form" onSubmit={this.contactForm.bind(this)}>
                                <div className="form-group">
                                    <input value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} name="name" id="name" type="text" className="form-control" required placeholder="Naam" />
                                </div>

                                <div className="form-group">
                                    <PhoneInput
                                        country={'nl'}
                                        regions={'europe'}
                                        value={this.state.number}
                                        onChange={number => this.setState({ number })}
                                        inputProps={{
                                            required: true,
                                        }}
                                    />
                                </div>

                                <div className="form-group">
                                    <input value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} name="email" id="email" type="text" className="form-control" required placeholder="E-mailadres" />
                                </div>

                                <div className="form-group">
                                    <input value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} name="password" id="password" type="text" className="form-control" placeholder="Wachtwoord" />
                                </div>

                                <div className="form-group">
                                    <textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} name="message" id="message" rows="5" className="form-control" required placeholder="Vraag"></textarea>
                                </div>
                                <div className="form-actions">
                                    <button className="button button-primary button-block button-big">Verzenden</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
             <Footer />
        </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Contact navigate={navigate} />
}

export default WithNavigate;