import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/backend/errorPages.scss';

class PageNotFound extends React.Component {
    
    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container error">
                        <h1>404</h1>
                        <h3>Pagina Niet Gevonden</h3>
                        <div className="error-desc">
                            De pagina die u zoekt is mogelijk verwijderd, tijdelijk niet beschikbaar of de naam ervan is gewijzigd.<br />
                            U kunt terug naar de vorige pagina gaan of naar het <a className="link" href="/">Homepage</a>.
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <PageNotFound navigate={navigate} />
}

export default WithNavigate;