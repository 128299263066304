import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import '../../styles/App.scss';
import '../../styles/backend/vonnisPages.scss'

class AllVonnisList extends React.Component {

    state = {
        vonnis: [],
        cocNumber: '',
        user: {},
    }

    componentDidMount() {
        setTimeout(() => {

            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    })
                    this.props.navigate('/login');
                } else {
                    this.setState({user: user});
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            })
            this.props.navigate('/login');
        });
    }

    getVonnisList(e) {
        e.preventDefault();
    
        if (this.state.cocNumber.length === 8) {
            const baseUrl = process.env.REACT_APP_API_BASEURL;

            axios.get(`${baseUrl}/api/vonnis/all/${this.state.cocNumber}/${this.state.user.id}`, {
                withCredentials: true,
            })
            .then(response => { 
                if (response.data.vonnisMine === 'unsubscribed') {
                    this.props.navigate('/aanmelden');
                }

                const vonnisMine = response.data.vonnisMine;
                this.setState({ vonnis: vonnisMine }, () => {

                    this.buildVonnisList(vonnisMine);

                    //HIDE TABLE AND DISPLAY ERROR IF NONE
                    var table = document.querySelector('.vonnis-table');
                    var alertNoReg = document.querySelector('.alert-warning.no-reg');
                    var alertReg = document.querySelector('.alert-warning.reg');

                    var info = document.querySelector('.alert-success');
                    var error = document.querySelector('.alert-error');

                    info.classList.add('hidden');
                    error.classList.add('hidden');

                    if (this.state.vonnis.length === 0) {
                        alertNoReg.classList.remove('hidden');
                        alertReg.classList.add('hidden');
                        table.classList.add('hidden');
                    } else {
                        alertNoReg.classList.add('hidden');
                        alertReg.classList.remove('hidden');
                        table.classList.remove('hidden');
                    }
                });
            })
            .catch(error => {
                axios.get(`${baseUrl}/api/clearauth/`, {
                    withCredentials: true,
                })
                this.props.navigate('/login');
            });
        } else {
            this.setState({ vonnis: [] }, () => {

                //HIDE TABLE AND DISPLAY ERROR IF NONE
                var table = document.querySelector('.vonnis-table');
                var alertNotReg = document.querySelector('.alert-warning.no-reg');
                var alertReg = document.querySelector('.alert-warning.reg');
                var info = document.querySelector('.alert-success');
                var error = document.querySelector('.alert-error');

                if (this.state.vonnis.length === 0) {
                    error.classList.remove('hidden');
                    alertNotReg.classList.add('hidden');
                    alertReg.classList.add('hidden');
                    info.classList.add('hidden');
                    table.classList.add('hidden');
                }
            });
        }
    }

    buildVonnisList(vonnisData) {
        if (vonnisData.length > 0) {
            return vonnisData.map((vonnisData, index) => (
                <tr key={index} className="vonnis visible">
                  <td>{vonnisData.companyName}</td>
                  <td>{vonnisData.vonnisDate.substring(0, vonnisData.vonnisDate.length - 14)}</td>
                  <td className="text-right">€ {vonnisData.vonnisPrice}</td>
                  <td>{vonnisData.vonnisPaid ? <div className="status-text text-closed">Is betaald</div> : <div className="status-text text-open">Is open</div>}</td>
                </tr>
            ));
        }
    }

    render() {
        return (
            <div className="Main">
                 <Header />
                 <div className="main-container grey-intro">
                    <div className="page-intro with-searchbox">
                        <div className="container mx-auto">
                            <h1>Raadplegen</h1>
                            <div className="intro">
                                <p>Zoek hier of er een KvK-nummer met een vonnis staat geregistreerd</p>
                            </div>
                        </div>
                    </div>
                 </div>
                 <div className="container mx-auto">
                    <div className="flex justify-center">
                        <form onSubmit={this.getVonnisList.bind(this)} className="searchbox-form">
                            <div className='grid grid-cols-12'>
                                <div className="form-group col-span-10">
                                    <input value={this.state.cocNumber} onChange={(e) => this.setState({cocNumber: e.target.value})} type="number" placeholder="Zoek op gedeelte KvK-nummer" className="searchbox-input form-control"/>
                                </div>
                                <div className="form-group col-auto">
                                    <button className="btn btn-default searchbox-button" type="submit"><FontAwesomeIcon icon={faSearch} /></button>
                                </div>
                            </div>
                        </form>
                    </div>
                 </div>
                 <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="table-responsive vonnis-table hidden">
                            <table className="data-table">
                                <thead>
                                    <tr>
                                        <th>Handelend onder de naam</th>
                                        <th>Datum vonnis</th>
                                        <th className="text-right">Gevorderd / toegewezen bedrag</th>
                                        <th>status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.buildVonnisList(this.state.vonnis)}
                                </tbody>
                            </table>
                        </div>

                        <div className="alert alert-success" role="alert">Vul hierboven een KVK nummer in om te zoeken.</div>

                        <div className="alert alert-warning no-reg hidden" role="alert">
                            Er is geen registratie bekend bij ons. U kunt dus gerust zaken doen met deze business klant.<br /><br />
                            <strong>Let op!</strong> Als er geen registratie bij ons bekend is wil dit niet zeggen dat er geen vonnis is. 
                            Mocht die er wel zijn heeft de schuldeiser deze nog niet laten registeren.
                        </div>
                        
                        <div className="alert alert-warning reg hidden" role="alert">
                            Het KvK-nummer waarop u gezocht heeft, staat geregistreerd op Vonnisbank.nl. Dat betekent dat er een vonnis
                            is uitgesproken tegen het geregistreerde bedrijf. U bent dus gewaarschuwd en wij adviseren u om goede afspraken
                            te maken, indien u zaken gaat doen met het bedrijf.
                        </div>

                        <div className="alert alert-error hidden" role="alert">Een KVK nummer bestaat uit 8 cijfers.</div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }

}

function WithNavigate() {
    const navigate = useNavigate();
    return <AllVonnisList navigate={navigate} />
}

export default WithNavigate;