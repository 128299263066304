import React from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode  from 'jwt-decode';

import axios from 'axios';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class ForgotPassword extends React.Component {

    state = {
        newPassword: '',
        newPasswordRepeat: '',
    }

    componentDidMount() {
        setTimeout(() => {
            
            //CALL AUTHORIZATION (BEFORE OTHER FUNCTIONS)
            this.auth();
        }, 0)
    }

    auth() {
        const baseUrl = process.env.REACT_APP_API_BASEURL;

        axios.get(`${baseUrl}/api/auth/`, {
            withCredentials: true,
        })
        .then(response => { 
            if (response.status === 200) {
                const user = jwt_decode(response.data.token);

                if (!user) {
                    axios.get(`${baseUrl}/api/clearauth/`, {
                        withCredentials: true,
                    });
                } else {
                    this.props.navigate('/account');
                }

            }
        })
        .catch(error => {
            axios.get(`${baseUrl}/api/clearauth/`, {
                withCredentials: true,
            });
        });
    }

    changePassword(e) {
		e.preventDefault();

        const baseUrl = process.env.REACT_APP_API_BASEURL;

        const samepass = document.querySelector('.password-same');
        const strengthpass = document.querySelector('.password-strength');
        const tokenerror = document.querySelector('.token-error');

        samepass.classList.add('hidden');
        strengthpass.classList.add('hidden');

        if (this.state.newPassword === this.state.newPasswordRepeat) {

            const newPassword = this.state.newPassword;
            const regularExpression = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{7,})"); // eslint-disable-line

            if (!regularExpression.test(newPassword)) {
                strengthpass.classList.remove('hidden');
            } else {
                const url = window.location.pathname;
                const params = url.split('/wachtwoordvergeten/');
    
                const id = params[0].replace('/', '');
                const token = params[1];
                
                axios.post(`${baseUrl}/api/${id}/forgotpassword/change/${token}`, 
                {
                    newPassword: this.state.newPassword,
                    newPasswordRepeat: this.state.newPasswordRepeat,
                }, {
                    withCredentials: true,
                })
                .then(response => {
                    // this.props.navigate('/login');
                    console.log(response);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        //Token ongeldig
                        tokenerror.classList.remove('hidden');
                    }
                });
            }

        } else {
             //Wachtwoord herhalen niet hetzelfde
             samepass.classList.remove('hidden');
        }

	}

    render() {
        return (
            <div className="Main">
                <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Wachtwoord aanpassen</h1>
                            <div className="intro">
                                <p>Vul hieronder uw nieuwe wachtwoord in.</p>            
                            </div>
                        </div>
                        <div className="form-wrapper">

                            <div className="alert alert-error password-same hidden" role="alert">Wachtwoorden komen niet overeen.</div>
                            <div className="alert alert-error password-strength hidden" role="alert">Uw wachtwoord moet voldoen aan de volgende voorwaarden: minimaal 1 kleine letter, 1 hoofdletter, 1 cijfer, 1 speciaal teken en minimaal 7 tekens lang.</div>
                            <div className="alert alert-error token-error hidden" role="alert">Link ongeldig, vraag een nieuwe aan.</div>

                            <form onSubmit={this.changePassword.bind(this)}>
                                <div className="form-group">
                                    <input value={this.state.newPassword} onChange={(e) => this.setState({newPassword: e.target.value})} type="password" placeholder="Wachtwoord" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <input value={this.state.newPasswordRepeat} onChange={(e) => this.setState({newPasswordRepeat: e.target.value})} type="password" placeholder="Wachtwoord herhalen" className="form-control"/>
                                </div>
                                <div className="form-group">
                                    <input type="submit" className="button button-primary button-block button-big" value="Wachtwoord aanpassen" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <ForgotPassword navigate={navigate} />
}

export default WithNavigate;