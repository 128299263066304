import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class FreeCta extends React.Component {

    render() {
        return (
            <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                        </div>

                        <p>
                            <strong>Gebruik Vonnisbank.nl</strong>
                        </p>

                    </div>
                </div>
             <Footer />
        </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <FreeCta navigate={navigate} />
}

export default WithNavigate;