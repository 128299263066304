import React from 'react';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class ContactSent extends React.Component {
    render() {
        return (
            <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Bericht verzonden</h1>
                            <div className="intro">
                                <p>We zullen zo snel mogelijk contact met u opnemen.</p>
                            </div>
                        </div>
                    </div>
                </div>
             <Footer />
        </div>
        );
    }
}

export default ContactSent