import React from "react";
import Logovisual from "../../../images/logo-visual.svg";
import Logotextual from "../../../images/logo-textual.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';


class Footer extends React.Component {
    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    render() {
        return (
        <div className="Footer">
           <div className="container mx-auto">
                <div className="main-footer">
                    <div className="flex flex-wrap">
                        <div className="md:basis-3/12 basis-full md:text-left text-center">
                            <div className="logo">
                                <img className="logo-visual" src={Logovisual} alt="logo visual" />
                                <img className="logo-textual" src={Logotextual} alt="logo textual" />
                            </div>
                        </div>
                        <div className="md:basis-3/12 basis-full md:text-left text-center">
                            <h3>Direct naar:</h3>
                            <ul>
                                <li><a href="/vonnis/zoeken" alt="vonnis zoeken">Vonnis zoeken</a></li>
                                <li><a href="/vonnis/plaatsen" alt="vonnis plaatsen">Vonnis plaatsen</a></li>
                                <li><a href="/kosten" alt="kosten">Overzicht &amp; kosten</a></li>
                                <li><a href="/faq" alt="veelgestelde vragen">Veelgestelde vragen</a></li>
                            </ul>
                        </div>
                        <div className="md:basis-3/12 basis-full md:text-left text-center">
                            <h3>Over Vonnisbank:</h3>
                            <ul>
                                <li><a href="/over-ons" alt="over ons">Over ons</a></li>
                                <li><a href="/hoe-het-werkt" alt="hoe het werkt">Hoe het werkt</a></li>
                                <li><a href="/contact" alt="contact">Service &amp; contact</a></li>
                            </ul>
                        </div>
                        <div className="md:basis-3/12 basis-full md:text-left text-center">
                            <h3>Voorwaarden:</h3>
                            <ul>
                                <li><a href="/privacy-verklaring" alt="privacy verklaring">Privacy verklaring</a></li>
                                <li><a href="/algemene-voorwaarden" alt="algemene voorwarden">Algemene voorwaarden</a></li>
                                <li><a href="/disclaimer" alt="disclaimer">Disclaimer</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sub-footer">
                    <div className="flex flex-wrap">
                        <div className="md:basis-3/12 basis-full flex md:justify-start justify-center">
                            <ul className="social">
                                <li><a href="https://www.facebook.com/vonnisbank.nl" alt="facebook" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></li>
                                <li><a href="https://www.linkedin.com/company/vonnisbank.nl" alt="linkedin" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                                <li><a href="tel:0138502866" alt="tel" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faPhone} /></a></li>
                                <li><a href="mailto:info@vonnisbank.nl" alt="mail" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faEnvelope} /></a></li>
                            </ul>
                        </div>
                        <div className="md:basis-7/12 basis-full text-center">
                            <span className="footer-text">© 2023 - Vonnisbank.nl BV, Mingersbergstraat 29, 5045 EM Tilburg <br/> <a href="tel:+31138502866">Tel: +31 13 850 28 66</a>, <a href="mailto:info@vonnisbank.nl">Mail: info@vonnisbank.nl</a></span>
                        </div>
                        <div className="md:basis-2/12 basis-full md:text-right text-center">
                            <p className="back-to-top" onClick={this.scrollToTop}>Terug naar boven</p>
                        </div>
                    </div>
                </div>
           </div>
        </div>
        )
    }
}

export default Footer;