import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Header, Footer } from '../../components/common';

import '../../styles/App.scss';
import '../../styles/pages/main-container.scss';

import Logovisual from "../../images/logo-visual-blue.svg";

class Kosten extends React.Component {

    render() {
        return (
            <div className="Main">
             <Header />
                <div className="bg-pattern front">
                    <div className="container mx-auto main-container">
                        <div className="page-intro">
                            <img src={Logovisual} className="logo-visual" alt="logo visual" />
                            <h1>Overzicht & kosten</h1>
                        </div>

                        <p>
                            <strong>Gebruik Vonnisbank.nl</strong>
                        </p>
                        
                        <p>Het gebruik van Vonnisbank.nl kost € 97,00 excl. btw. per jaar.</p>

                        <p>Na uw aanmelding ontvangt u een factuur.</p>
                        <ul>
                            <li>U kunt dan onbeperkt vonnissen registreren</li>
                            <li>Onbeperkt vonnissen raadplegen</li>
                            <li>Woord- beeldmerk van Vonnisbank.nl gebruiken op aanmaningen, ter bevordering van de betalingsmoraal van uw debiteuren</li>
                        </ul>

                        <p>Voor professionele incassopartners (incassobureaus en deurwaarders) hebben wij een businessaccount. Het uploaden van een vonnis is dan niet nodig.</p>

                        <p>
                            <strong>Her-incasso oude vonnissen</strong>
                        </p>

                        <p>Her-incasso van oude vonnissen, kost € 200,00 excl. btw. per vonnis. Oude vonnissen die een aantal jaren geleden als oninbaar werden afgeboekt, hoeven nu niet oninbaar te zijn. Bij succesvol incasseren ontvangt de schuldeiser 50% van het ontvangen bedrag, onder aftrek van eventuele kosten deurwaarder en kosten van een extern onderzoeksbureau.</p>

                        <p>
                            <strong>Bedrijfsrapporten</strong>
                        </p>

                        <p>Vonnisbank.nl biedt aan de aangesloten bedrijven de mogelijkheid om een bedrijfsrapport aan te vragen. Een bedrijfsrapport biedt inzage in de kredietwaardigheid en de organisatiestructuur van een (potentiële) klant. Het bedrijfsrapport kost € 12,50 excl. btw.</p>

                    </div>
                </div>
             <Footer />
        </div>
        );
    }
}

function WithNavigate() {
    const navigate = useNavigate();
    return <Kosten navigate={navigate} />
}

export default WithNavigate;