import React from "react";

class Usp extends React.Component {
    render() {
        return (
            <div className="Usp text-center">
                <div className="container mx-auto">
                    <div className="columns-3">
                        <p>Preventief</p>
                        <p>Rechtvaardig</p>
                        <p>Kostenverlagend</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Usp;