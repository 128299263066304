import React from "react";

class Dashboard extends React.Component {

    render() {
        return (
            <div className="container mx-auto main-container dashboard">
                <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'>
                    <div>
                        <div className="dashboard-box h-full flex items-center justify-center">
                            <a href="/vonnis/plaatsen">
                                <h2 className="text-center md:text-2xl text-xl">Vonnis plaatsen</h2>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="dashboard-box">
                            <a href="/bedrijfsrapport/opvragen">
                                <h2 className="text-center md:text-2xl text-xl">Bedrijfsrapport <br /> (opvragen)</h2>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="dashboard-box">
                            <a href="/herincasso/plaatsen">
                                <h2 className="text-center md:text-2xl text-xl">Her-incasso <br /> (indienen)</h2>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className="dashboard-box h-full flex items-center justify-center">
                            <a href="/incasso/plaatsen">
                                <h2 className="text-center md:text-2xl text-xl">Incasso opdracht <br /> (indienen)</h2>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;