import React from "react";

class FileInput extends React.Component {
    render() {
        return (
            <div className="form-group">
                <input type="file" placeholder="Vonnis (PDF)" accept="application/pdf" className="form-control" required />
            </div>
        )
    }
}

export default FileInput;