import Header from "./header";
import Hero from "./hero";
import Usp from "./usp";
import Faq from "./faq";
import Footer from "./footer";
import Dashboard from "./dashboard";
import FileInput from "./form";

export {
    Header,
    Hero,
    Usp,
    Faq,
    Footer,
    Dashboard,
    FileInput
}